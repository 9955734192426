export const FR = {
  key: "FR",
  app_name: "TriScan",
  header_navigation_home: "Accueil",
  header_navigation_about: "A propos",
  header_navigation_ranking: "Classement",

  home_title: "Battez notre IA and venez en aide à l'environement!",
  home_text: `L'Intelligence Artificielle pourrait nous aider à mieux trier nos déchets ménagers ? 
  Pour cela nous avons besoin de votre aide !
  Comment ? En nous aidant à l'entraîner en lui indiquant si elle a tort ou raison.
  Prêt à jouer ? 
  Merci de ne charger que des emballages vides.`,
  
  upload_button_text: "Prendre en photo",
  random_button_text: "Aléatoire",
  report_button_text: "Signaler une erreur",
  report_success_text: "Rapport envoyé",
  under_development_text: "Bientôt",
  PMD: "PMC",
  PAPER: "Papiers-Cartons",
  GLASS: "Verre",
  GFT: "Déchets organiques",
  KGA: "Petits déchets dangereux",
  REST: "Déchets résiduels",
  OTHER: "Autre",
  NO_WASTE: "Pas de déchet",
  validating_guess_text: "Validation en cours ...",
  good_answer_title: "Genial ! Vous êtes un expert en tri!",
  good_answer_text: "Vos réponse correspond à la prédiction de notre IA",
  call_to_action: "Prenez une nouvelle photo, ou continuer à jouer avec une image aléatoire prise par la communauté!",
  wrong_answer_title: "Oh, Notre IA n'est pas d'accord avec vous!",
  wrong_answer_text_before_fraction: "Notre IA a estimé une autre réponse :",
  wrong_answer_text_after_fraction: "Vous pensez qu'il s'agit d'un erreur ? Signalez le nous, ou continuer à jouer.",
  about_section: `<div>
   <h1>Triscan, c'est quoi ?</h1>
   <p>
     Triscan est une application expérimentale qui a pour but d'améliorer le modèle de prédiction de notre IA.
	 Avec votre aide nous pouvons le perfectionner et rendre le recyclage plus éfficace encore !
 
 <a href="/privacy">Respect de la vie privée</a> - <a href="/terms_of_use">Conditions d'utilisation</a>	
   </p>
 </div>
 <div>
   <h1>Règles du jeu</h1>
   <p>
   Les règles sont simples, vous gagnez des points pour chacune de vos actions: </br>
   500 points par bonne réponse</br> 
   100 points par image chargée</br>
   200 points par correction</br>
   50 points par tentative</br>
      </p>
 </div>`,
  privacy_html: `<div>
<p>
    Déclaration de confidentialité du Click par Fost Plus
</p>
<h1>
    1. Objet et finalité
</h1>
<p>
    Fost Plus respecte votre vie privée et agit donc toujours conformément aux
    règles en vigueur en matière de protection des données (y compris le RGPD –
    Règlement Général sur la Protection des Données).
</p>
<p>
    La présente Déclaration de confidentialité contient de plus amples
    informations sur la façon dont les données à caractère personnel des
    utilisateurs de l’appli sont collectées, utilisées et traitées par l’appli
    disponible dans l’App Store (ci-après dénommée « l’Appli ») ainsi que sur
    le site internet sous la responsabilité de Fost Plus, et précise si elles
    sont transmises à des tiers.
</p>
<p>
    En nous communiquant vos données à caractère personnel, vous reconnaissez
    avoir pris connaissance des conditions générales suivantes, en vertu
    desquelles Fost Plus traite et transmet les données à caractère personnel.
</p>
<p>
    Si vous nous communiquez les données à caractère personnel d’autres
    individus, vous garantissez que (i) l’individu visé a été informé de la
    présente déclaration de confidentialité, et que (ii) pour autant que
    certains traitements nécessitent un consentement, vous avez obtenu de
    l’individu visé le consentement nécessaire auxdits traitements par Fost
    Plus.
</p>
<p>
    Vous trouverez de plus amples informations sur l’utilisation de cookies
    dans notre Politique en matière de cookies.
</p>
<h1>
    2. Responsable du traitement des données
</h1>
<p>
    Le responsable du traitement des données est : Fost Plus asbl (organisation
    sans but lucratif)
</p>
<p>
    Siège social : 2 Avenue des Olympiades, 1140 Bruxelles
</p>
<p>
    Numéro d’entreprise : 0447.550.872
</p>
<p>
    Téléphone : 0032 (0) 2 775 02 50
</p>
<p>
    E-mail : <a href="mailto:privacy@fostplus.be">privacy@fostplus.be</a>
</p>
<h1>
    3. Données à caractère personnel, finalités et bases légales
</h1>
<p>
    Fost Plus collecte et traite différentes catégories de données à caractère
    personnel vous concernant (ou l’individu concerné). Ces données peuvent
    être collectées et traitées lors des processus suivants notamment : lorsque
    vous complétez le formulaire en ligne d’enregistrement dans l’Appli,
    lorsque vous utilisez l’Appli, lorsque vous enregistrez votre emplacement
    lors d’un évènement où l’Appli est active, en échangeant des Circular
    UCoins contre des bons ou en utilisant le formulaire de contact disponible
    sur le site web ou par e-mail.
</p>
<p>
    Le tableau ci-dessous énumère les données à caractère personnel que nous
    traitons, avec quelles finalités et sur quelles bases légales applicables.
</p>
<table border="1" cellspacing="0" cellpadding="0">
    <tbody>
        <tr>
            <td width="200" valign="top">
                <p>
                    <strong>Finalité</strong>
                </p>
            </td>
            <td width="200" valign="top">
                <p>
                    <strong>Données à caractère personnel traitées</strong>
                </p>
            </td>
            <td width="200" valign="top">
                <p>
                    <strong>Base légale</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td width="200" valign="top">
                <p>
                    <strong>
                        La reconnaissance d’un fraction sur base d’une image
                        partagée
                    </strong>
                </p>
            </td>
            <td width="200" valign="top">
                <p>
                    Pas de données à caractère personnel, cependant les images
                    peuvent contenir de manière indirecte des informations à
                    caractère personnel qui ne concernent ou pas l’utilisateur
                </p>
                <p>
                    Cette image peut être utilisé publiquement
                </p>
            </td>
            <td width="200" valign="top">
                <p>
                    Nécessaire pour exécuter votre demande (c'est-à-dire en
                    accord avec les conditions d'utilisation de
                    l'application/du site Web).
                </p>
            </td>
        </tr>
        <tr>
            <td width="200" valign="top">
                <p>
                    <strong>Demande d’information</strong>
                </p>
            </td>
            <td width="200" valign="top">
                <p>
                    Dans le cas ou une demande ne peut pas être traitée
                    automatiquement l’utilisateur peut introduire une demande
                    de traitement manuel, dans quel cas sont communiqués :
                </p>
                <p>
                    Nom
                </p>
                <p>
                    Prénom
                </p>
                <p>
                    adresse email
                </p>
                <p>
                    code postal.
                </p>
            </td>
            <td width="200" valign="top">
                <p>
                    Avec la permission
                </p>
            </td>
        </tr>
    </tbody>
</table>
<p>
    À chaque utilisation de l’application ou du site internet, différentes
    données à caractère personnel et différents fichiers journaux vous
    concernant peuvent être collectés automatiquement, à savoir notamment votre
    adresse IP, la date et la durée de votre utilisation de l’Appli, votre
    navigateur et votre système d’exploitation. Ces données sont utilisées
    exclusivement à des fins statistiques et pour suivre, analyser et optimiser
    le fonctionnement et l’utilisation de l’application/site internet, dans
    notre intérêt légitime à améliorer l’Appli et nos services. En principe,
    ces données ne sont pas conservées pour une durée de plus d’un an.
</p>
<p>
    L’application et le site internet doivent avoir accès à l’appareil photo de
    votre appareil pour vous permettre de prendre des photos de détritus ou de
    scanner les codes-barres de détritus. Lorsqu’ils envoient des photos à
    l’application/site internet, les utilisateurs sont encouragés à ne pas
    révéler d’autres données à caractère personnel les concernant ou concernant
    d’autres personnes physiques, en particulier des catégories spéciales de
    données à caractère personnel. L’utilisateur qui envoie de telles
    informations sur lui-même ou sur d’autres personnes est tenu de fournir un
    motif légal à la révélation de ces informations.
</p>
<h1>
    4. Transmission à des tiers
</h1>
<p>
    Aux fins visées ci-avant, Fost Plus fait appel à des prestataires de
    services tiers, dont des prestataires de services IT. Si nécessaire à
    l’exécution de leur tâche, il se peut que vos données à caractère personnel
    soit transmises à ces prestataires de services tiers. Dans ce cas, ils se
    contenteront de traiter vos données à caractère personnel dans la limite
    des instructions données par Fost Plus et dans le respect des règles
    applicables.
</p>
<p>
    Fost Plus peut également transmettre certaines données à caractère
    personnel à des parties externes pour qu’elles les traitent à leurs propres
    fins et par leurs propres moyens. Dans ce cas, ces tiers agiront en tant
    que responsable du traitement des données, dans les limites de la
    législation applicable sur la protection des données. Ces actions incluent
    la transmission de ces données aux autorités compétentes à des fins de
    procédures judiciaires urgentes ou en cours, dans le contexte
    d’investigations judiciaires et de demandes d’informations, à la demande du
    gouvernement ou pour honorer d’autres obligations légales.
</p>
<h1>
    5. Transmission à des pays extérieurs à l’EEE
</h1>
<p>
    Pour l’instant, nous ne transmettons pas vos données à des pays situés en
    dehors de l’Espace économique européen (EEE). Si cela devait changer, nous
    veillerons à ce que vos données à caractère personnel soit transmises
    uniquement à des parties appliquant un degré approprié de protection des
    données (p. ex. en incluant les clauses contractuelles types de l'Union
    européenne dans le contrat conclu avec le destinataire sous condition d'une
    évaluation appropriée des risques).
</p>
<h1>
    6. Vos droits en tant que personne concernée par la transmission de données
</h1>
<p>
    En tant que personne concernée par la transmission de données, vous
    disposez des droits suivants :
</p>
<p>
    <strong>Droit d’accès</strong>
    – Vous avez le droit de consulter vos données à caractère personnel que
    nous traitons, et d’obtenir une copie de ces données (à certaines
    exceptions près).
</p>
<p>
    <strong>Droit à la portabilité des données</strong>
    – Sous certaines conditions, vous avez le droit de recevoir les données à
    caractère personnel que vous avez communiquées, dans un format structuré,
    d’usage et courant et lisible par machine, de façon à pouvoir transmettre
    ces données à une autre partie.
</p>
<p>
    <strong>Droit de rectification et de suppression</strong>
    – Vous avez, à tout moment, la possibilité de nous faire corriger ou
    supprimer les données que vous nous avez transmises, gratuitement et
    moyennant le respect des conditions légales applicables. Les données dont
    nous avons besoin pour l’établissement, l’exercice ou la défense de
    revendications légales, ou que nous sommes légalement tenus de conserver,
    ne sont pas concernées par ce droit de suppression.
</p>
<p>
    <strong>Restriction du traitement</strong>
    – Pour autant que les conditions légales pertinentes soient remplies, vous
    pouvez exiger que nous restreignions notre traitement de vos données à
    caractère personnel.
</p>
<p>
    <strong>Droit d’objection </strong>
    – Vous pouvez à tout moment objecter au traitement de vos données à
    caractère personnel à des fins de marketing direct ou qui utilise nos
    intérêts légitimes comme base légale. Lorsque vous objectez au traitement
    de vos données à caractère personnel à des fins de marketing direct, vous
    n’êtes pas tenu(e) de justifier votre objection.
</p>
<p>
    <strong>Retrait de votre consentement</strong>
    – Si nous traitons vos données moyennant votre consentement, vous avez le
    droit de retirer ce consentement à tout moment. Notez toutefois que ce
    retrait n’influencera en rien le caractère légal du traitement de vos
    données à caractère personnel pour la période précédant le moment de ce
    retrait, ni des activités de traitement fondées sur d’autre bases légales.
</p>
<p>
    <strong>Dépôt de plainte auprès de l’autorité compétente</strong>
    – Vous avez à tout moment le droit de contacter l’autorité belge de
    protection des données et d’introduire une plainte auprès d’elle si cela
    est approprié.
</p>
<p>
    Pour exercer les droits ci-dessus, envoyez un e-mail à privacy@fostplus.be
    Cet e-mail doit être accompagné de vos coordonnées complètes et d’un moyen
    de vérification de votre identité (p. ex. une copie du recto de votre carte
    d’identité ou permis de conduire). Fost Plus peut cependant refuser toute
    requête qu’elle jugera excessive ou constituant un abus vis-à-vis de la
    législation pertinente.
</p>
<h1>
    7. Stockage de vos données
</h1>
<p>
    En principe, Fost Plus ne conserve vos données à caractère personnel que
    pendant la période nécessaire aux fins décrites ci-avant (compte tenu des
    délais légalement prescrits applicables à ce contexte). Par exemple, des
    données à caractère personnel contenues dans les questions que vous nous
    posez ne sont en principe conservées que pendant une période de deux ans à
    dater de la réception de votre question. Cependant, les données que nous
    traitons – dans la mesure où vous le souhaitez – pour vous tenir informé(e)
    de nos initiatives et autres seront tenues à jour et traitées aussi
    longtemps que vous accepterez de recevoir des newsletters, du marketing
    (digital) ou d’autres informations. Les données à caractère personnel liées
    aux documents contractuels (p. ex. les données d’identification ou de
    transaction) seront conservées aussi longtemps que votre compte sur the
    Click sera actif et pour une période maximale de dix ans à dater de la fin
    de votre contrat avec Fost Plus, sauf exceptions légales. À l’issue de ces
    périodes, les données seront effacées.
</p>
<p>
    Votre compte restera actif jusqu’à ce que vous demandiez sa désactivation
    ou sa suppression.
</p>
<h1>
    8. Modifications
</h1>
<p>
    Fost Plus se réserve le droit de modifier la présente déclaration de
    confidentialité à tout moment, notamment pour l’adapter aux changements qui
    surviendraient dans les services fournis, ou aux amendements qui seraient
    apportés aux obligations légales et/ou règlementaires. Toute modification
    apportée à la présente déclaration de confidentialité entrera en vigueur au
    moment de sa publication. Nous vous recommandons de toujours consulter la
    version la plus récente. La présente déclaration de confidentialité a été
    publiée le 25 juillet 2020 mise à jour dernièrement le 25 juin 2021.
</p>
<h1>
    9. Coordonnées du data protection manager et des autorités de protection
    des données.
</h1>
<p>
    Pour toute question sur le traitement de vos données à caractère personnel
    ou sur l’exercice de vos droits en tant que personne concernée, vous pouvez
    contacter à tout moment notre Data Protection Manager (DPM). Notre DPM a
    été désigné spécifiquement pour, entre autres tâches, faire office de
    personne de contact avec les personnes dont nous traitons les données.
</p>
<p>
    Vous pouvez le joindre par e-mail (privacy@fostplus.be), par téléphone au
    +32 2 775 03 50 ou par courrier à l’adresse suivante : Fost Plus Data
    Protection Manager, 2 Avenue des Olympiades, 1140 Bruxelles, Belgique.
</p>
<p>
    Vous pouvez également adresser vos questions et plaintes à l’Autorité de
    protection des données en Belgique (
    <a href="https://www.autoriteprotectiondonnees.be/">
        https://www.autoriteprotectiondonnees.be/
    </a>
    ).
</p>
 </div>`,
  terms_of_use_html: `<div>
<p>
    Conditions d’utilisation – Triscan.Be par Fost Plus
</p>
<h1>
    1. Informations générales
</h1>
<p>
    Vous trouverez ici les conditions d’utilisation de notre application
    Triscan.be (ci-après dénommée <strong>« l’Appli »</strong>). L’Appli est
gérée par ou au nom de Fost Plus asbl (ci-après référencée par les termes    <strong>« nous », « notre » et « nos »</strong>). Nous sommes une
    organisation sans but lucratif, établie en Belgique sous le numéro
    d’entreprise 0447.550.872, et dont le siège social est sis à l’adresse 2
    Avenue des Olympiades, 1140 Bruxelles, Belgique.
</p>
<p>
    Nous demeurons à tout moment le propriétaire de l’Appli, et nous vous
    autorisons à utiliser cette application et les services offerts à travers
elles sur la base des conditions d'utilisation (ci-après dénommées les    <strong>« Conditions »</strong>). En marquant votre accord avec ces
    Conditions sur l’écran d’enregistrement de l’Appli, vous reconnaissez avoir
    lu et être lié par ces Conditions, et vous acceptez de n’utiliser l’Appli
    que dans le respect de ces Conditions. Si vous ne souscrivez pas au contenu
    de ces Conditions, veuillez ne pas continuer d’utiliser l’Appli.
</p>
<p>
    L’utilisation de vos données à caractère personnel soumises à ou par le
    biais de l’Appli est régie par notre Déclaration de confidentialité, qui
    explique comment nous utilisons vos données à caractère personnel.
</p>
<p>
    Votre utilisation de l’Appli peut également être sujette aux conditions ou
    politiques appliquées par tout prestataire ou opérateur tiers à partir du
    site ou de la plateforme duquel vous avez téléchargé l'Appli (p. ex.
    fournisseur de navigateur web, appstore). Nous vous encourageons à lire et
    à adhérer à ces conditions et politiques.
</p>
<p>
Les présentes Conditions ont été mises à jour le    <strong><em>25 juin 2021</em></strong>. La dernière version de ces
    Conditions est toujours disponible via l’Appli. Il vous incombe de passer
    en revue les Conditions applicables à intervalles réguliers, et de vous
    conformer à la dernière version.
</p>
<h1>
    2. Finalité et contenu de l’Appli
</h1>
<p>
    Aux fins des présentes Conditions, nous entendons par « détritus » : les
    emballages ou déchets d’articles et marchandises utilisés hors du domicile
    (p. ex. lors de festivals ou autres évènements similaires) ou les
    emballages jetés dans des lieux publics. Les déchets ménagers sont exclus
    de cette description. Ils ne doivent donc pas être communiqués et ne seront
    pas comptabilisés.
</p>
<p>
    Nous avons créé l’Appli afin d’encourager la circularité et d’inciter les
    individus à ramasser leurs détritus et à les jeter dans la poubelle
    adéquate. En photographiant des détritus ou en scannant leurs codes-barres
    afin d’obtenir en retour un conseil de tri.
</p>
<p>
    En photographiant plusieurs articles de détritus ou en scannant leurs
    codes-barres, vous agrandissez notre base de donnée ainsi que la chance
    d’obtenir une conseil de tri adéquat.
</p>
<h1>
    3. Comptes
</h1>
<p>
    Veuillez n’utiliser l’Appli que si vous acceptez d’y accéder et de
    l’utiliser conformément aux présentes Conditions.
</p>
<p>
    <strong>
        Si vous êtes âgé(e) de moins de 16 ans, vous devez obtenir la
        permission d’un adulte ayant responsabilité parentale sur vous avant
        d’accéder à l’Appli et de l’utiliser.
    </strong>
</p>
<p>
    Pour utiliser l’Appli, vous devez créer et enregistrer un compte chez nous
    en complétant le formulaire d’enregistrement de compte fourni dans l’Appli.
    Pour ce faire, vous devrez fournir votre nom et votre adresse e-mail. Vous
    ne devez vous enregistrer qu’une fois. Vous êtes tenu(e) de nous fournir
    des informations authentiques, exactes, complètes et actuelles. Vous êtes
    responsable des informations que vous nous fournissez.
</p>
<p>
    Lorsque vous enregistrerez un compte chez nous, il vous sera demandé de
    créer un mot de passe. Votre mot de passe doit rester confidentiel en
    permanence et peut uniquement être utilisé pour accéder à votre compte et
    vous en servir. Vous êtes l’unique utilisateur autorisé de votre compte, ce
    qui signifie que vous êtes tenu(e) de ne pas divulguer votre mot de passe à
    autrui et de ne pas utiliser l’adresse e-mail ou le mot de passe d’autrui.
    Vous comprenez et acceptez que nous ne pouvons être tenus responsables des
    conséquences de tout usage illégal et non autorisé de l’Appli par le biais
    de vos nom d’utilisateur et mot de passe. Veuillez nous contacter
    immédiatement si vous découvrez que votre compte a été utilisé de façon non
    autorisée ou en cas d’erreur de fonctionnement de votre mot de passe (voir
    section Communication ci-dessous pour plus de détails). Vous êtes
    responsable de toutes les actions effectuées par le biais de votre compte.
    Toute infraction aux présentes Conditions et/ou utilisation de votre compte
    par une personne à laquelle vous avez révélé votre mot de passe sera
    considérée comme étant de votre propre fait et ne vous déchargera nullement
    de vos obligations envers nous.
</p>
<h1>
    4. Utilisation de l’Appli
</h1>
<h2>
    4.1.Votre utilisation
</h2>
<p>
    L’utilisation de l’Appli est gratuite. Vous avez uniquement besoin d’une
    connexion Internet.
</p>
<p>
    Vous ne pouvez utiliser l’Appli qu’à des fins non commerciales (sauf
    autorisation expresse et écrite de notre part) et en conformité avec les
    présentes Conditions. Vous acceptez que vous utilisez l’Appli « en état »
    et « telle que disponible », et à votre seul risque.
</p>
<p>
    Vous comprenez que pour pouvoir utiliser l’Appli correctement, vous devez
    autoriser l’Appli à accéder aux éléments suivants :
</p>
<p>
    - <u>Votre appareil photo</u> : afin que vous puissiez prendre des photos,
    scanner des codes-barres. L’Appli n’accèdera à l’appareil photo que lorsque
    vous utiliserez l’Appli.
</p>
<p>
    Si vous n’autorisez pas l’Appli à accéder à votre appareil photo, vous ne
    pourrez pas profiter pleinement des fonctionnalités de l’Appli.
</p>
<p>
    Sauf dans la mesure expressément définie par les présentes Conditions, vous
    n’êtes pas autorisé(e) à :
</p>
<p>
    - « capter » ou stocker du contenu de l’Appli sur un serveur ou autre
    appareil de stockage connecté à un réseau, ni créer une base de données
    électronique en téléchargeant et stockant systématiquement tout le contenu
    de l’Appli, ni « répliquer » (<em>mirroring</em>) l’Appli sur un autre
    serveur ou application, sans notre consentement écrit préalable ;
</p>
<p>
    - soumettre à l’Appli du contenu non authentique, trompeur ou frauduleux,
    ou qui pourrait être considéré comme diffamatoire, discriminatoire, abusif,
    offensant ou autrement répréhensible, ou qui porte atteinte aux droits de
    tiers ;
</p>
<p>
    - supprimer ou modifier tout contenu de l’Appli, ou tenter de contourner
    les mesures de protection, de sécurité ou de confidentialité, ou interférer
    dans le bon fonctionnement de l’Appli ou des serveurs sur lesquels l’Appli
    est hébergée ;
</p>
<p>
    - procéder à la republication, la redistribution, la rétroconception,
    l’exploitation ou la retransmission de l’Appli et/ou de toute partie du
    contenu mis à disposition sur l’Appli ; ou
</p>
<p>
    - vous livrer autrement à une action non autorisée expressément par les
    présentes Conditions.
</p>
<p>
    Vous ne pouvez utiliser l’Appli qu’à des fins légales (c’est-à-dire
    conformes à tous les règlements et lois applicables), de manière
    responsable et non susceptible de porter atteinte à notre nom ou à notre
    réputation ni à celle de l’un ou l’autre de nos employés, agents ou
    représentants.
</p>
<p>
    Nous vous donnons personnellement le droit d’utiliser l’Appli de la façon
    décrite dans les présentes Conditions. Vous ne pouvez pas transférer à
    autrui vos droits ou obligations découlant des présentes Conditions sans
    notre accord écrit préalable. Vous acceptez que si vous vendez un appareil
    sur lequel l'Appli est installée, vous devez d'abord retirer l'Appli de cet
    appareil.
</p>
<h2>
    4.2. Interruption du fonctionnement de l’Appli
</h2>
<p>
    Nous ne pouvons garantir le fonctionnement continu, ininterrompu ou sans
    défaillance de l’Appli. Nous pouvons à tout moment, à notre seule
    discrétion et avec ou sans avertissement préalable, suspendre
    temporairement le fonctionnement de certaines fonctionnalités, parties ou
    éléments de contenu de l’Appli (totalement ou partiellement) pour des
    raisons de réparation ou de maintenance, afin de retirer, modifier, mettre
    à jour ou mettre à niveau des éléments de contenu, des caractéristiques ou
    des fonctionnalités.
</p>
<p>
    Vous acceptez que nous ne pourrons être tenus responsables, envers vous ou
    envers tout autre tiers, conformément aux conditions de responsabilité
    énoncées à la section Garanties et responsabilité, d’aucune
    indisponibilité, modification, interruption, ou suppression de l’Appli, ou
    de l’un(e) ou l’autre fonctionnalité, partie ou élément de contenu de
    l’Appli.
</p>
<p>
    L’Appli se connecte à des services via la connexion Internet fournie par
    votre prestataire de services web tiers ou par le réseau de votre
    prestataire de services mobiles, et une connexion Internet stable est
    requise pour accéder à nos services. Vous reconnaissez et acceptez que nous
    ne pouvons être tenus responsables de l’indisponibilité de la connexion
    Internet ni des problèmes de qualité de la connexion, des interruptions du
    service ou du mauvais fonctionnement de l’Appli pouvant survenir suite à
    l’indisponibilité partielle ou totale d’Internet ou suite à un problème de
    connectivité de votre appareil.
</p>
<h2>
    4.3.Fin de notre relation
</h2>
<p>
    Si vous enfreignez l’une ou l’autre des présentes Conditions, nous pouvons,
    avec ou sans avertissement préalable, suspendre ou mettre fin à la relation
    contractuelle qui nous unit, ou à l’utilisation que vous faites de votre
    compte.
</p>
<p>
    Si vous enfreignez ces Conditions, nous sommes en droit d’entreprendre
    toute démarche que nous jugerons appropriée. Toute infraction de ce type
    commise par vous peut entraîner toutes ou l’une des actions suivantes dans
    notre chef, avec ou sans avertissement préalable :
</p>
<p>
    - envoi d’un avertissement à votre intention ;
</p>
<p>
    - suppression immédiate, avec effet temporaire ou permanent, de tout
    contenu soumis par vos soins ;
</p>
<p>
    - retrait immédiat, avec effet temporaire ou permanent, de votre droit
    d’accès à et d’utilisation de l’Appli ;
</p>
<p>
    - actions en justice à votre encontre en vue d'obtenir le remboursement de
    tous les préjudices et pertes récupérables résultant de votre infraction ;
    et/ou
</p>
<p>
    - révélation de toutes les informations pertinentes pour les autorités
    judiciaires, dans la mesure de ce que nous jugerons raisonnablement
    nécessaire.
</p>
<p>
    Les droits précités sont cumulatifs et non exhaustifs, et nous pouvons
    entreprendre toute autre action que nous jugerons appropriée.
</p>
<p>
    Lorsqu’il aura été mis fin aux présentes Conditions ou à votre compte, pour
    quelque raison que ce soit et avec ou sans intervention judiciaire :
</p>
<p>
    - tous les droits qui vous auront été octroyés en vertu des présentes
    Conditions prendront fin immédiatement ;
</p>
<p>
    - vous devrez cesser immédiatement tout usage de l’Appli et supprimer ou
    retirer l'Appli de tous les appareils (mobiles) en votre possession.
</p>
<p>
    Vous pouvez mettre fin à notre relation contractuelle en nous demandant de
    désactiver votre compte.
</p>
<h1>
    5. Garanties et responsabilité</a>
</h1>
<p>
    Bien que nous entreprenions toutes les démarches raisonnablement
    nécessaires pour que l’Appli soit préservée de tout logiciel malveillant ou
    de virus, nous ne pouvons garantir et ne garantissons pas que l’Appli sera
    entièrement dépourvue de virus et/ou autre code pouvant contenir des
    éléments contagieux ou destructeurs. C’est à vous qu'il incombe de mettre
    en place les barrières de sécurité IT adéquates (en ce compris :
    l'installation de mises à jour de l'Appli et l’exécution d’une analyse
    antivirus et autres vérifications de sécurité) pour répondre à vos critères
    spécifiques en matière de sécurité et de fiabilité du contenu auquel vous
    accédez via notre Appli.
</p>
<p>
    Vous comprenez et acceptez que, hormis dans les cas où la loi n’autorise
    pas l’exclusion de la responsabilité, nous ne pouvons en aucun cas
    (indépendamment de la gravité de la faute commise) être tenus responsables,
    de manière générale ou spécifique, directe ou indirecte, de quelque
    préjudice direct ou indirect que ce soit, à savoir entre autres les
    préjudices résultant d’une perte de profits, d’une perte de données, d’une
    perte commerciale, d’une interruption des activités commerciales, d’une
    perte d’opportunités commerciales ou de tout autre préjudice ou perte
    d’ordre commercial résultant de (i) votre utilisation de l’Appli ou votre
    incapacité à utiliser l’Appli, (ii) toute modification apportée à l’Appli
    ou l’interruption de l’activité de l’Appli, (iii) la suppression, le
    préjudice ou le manquement au stockage du contenu et des autres données
    détenues ou transmises par utilisation de l’Appli, (iv) le non-respect par
    les vendeurs tiers de leurs obligations dans le cadre de leurs transactions
    avec vous ou (iv) la force majeure.
</p>
<p>
    Vous acceptez de nous indemniser intégralement et de nous prémunir contre
    toute réclamation émanant d’un tiers, suite à ou en rapport avec la
    violation des présentes Conditions.
</p>
<p>
    Votre utilisation de l’Appli n’entraîne nullement ni ne constitue
    l’établissement d’un rapport d’agence, de partenariat, de coentreprise, de
    travail ou de franchise entre vous-même et nous.
</p>
<h1>
    6. Droits de propriété intellectuelle
</h1>
<p>
    Vous reconnaissez que tous les droits de propriété intellectuelle sur tout
    élément de contenu de l’Appli (en ce compris le texte, les graphiques, le
    logiciel, les photos et autres images, les vidéos, l’audio, les marques
    déposées et les logos) sont détenus par nous ou par nos donneurs de
    licence.
</p>
<p>
    Sauf mention contraire explicite dans les précédentes conditions ou
    notifiées par nous sous forme écrite, aucun élément des présentes
    Conditions ne vous donne de droit sur aucune forme de propriété
    intellectuelle détenue par nous ou par nos donneurs de licence, et vous
    reconnaissez n’acquérir aucun droit de propriété ni d’utilisation lorsque
    vous téléchargez du contenu depuis l’Appli. Si vous veniez à imprimer,
    prendre une capture d’écran, copier ou stocker des pages tirées de l’Appli,
    vous êtes tenu(e) de veiller à ce que toute mention de droit d’auteur, de
    marque déposée ou d’autres droits de propriété intellectuelle reprise dans
    le contenu d’origine soit reproduite également.
</p>
<p>
    Il appartient à l'utilisateur de vérifier que les informations/photos ou
    qu'il met à disposition via l'application/site internet sont libres de tout
    droit de propriété intellectuelle et/ou concède une licence illimitée et
    perpétuelle d'utilisation de la photo/information, et ce aussi à l'égard de
    tiers ou du grand public.
</p>
<h1>
    <a name="_Ref43366354">7. Communication</a>
</h1>
<p>
    Toutes vos notifications à notre intention doivent nous être adressées par
    écrit, à l’adresse postale ou adresse e-mail mentionnée ci-dessous. Nous
    pouvons vous envoyer des notifications à l’adresse e-mail que vous nous
    avez fournie.
</p>
<p>
    Veuillez soumettre toutes vos questions sur les présentes Conditions ou sur
    l’Appli, toutes vos plaintes ou inquiétudes relatives à l’Appli ou tous les
    problèmes que vous souhaitez rapporter concernant l’Appli par e-mail à
l’adresse info@triscan.be ou par courrier postal à l’adresse :    <em>Fost Plus, 2 Avenue des Olympiades, 1140 Bruxelles, Belgique</em>
</p>
<h1>
    8. Modifications
</h1>
<p>
    Étant donné que nous avons pour politique de constamment réévaluer et
    mettre à jour l’Appli, nous sommes susceptibles de revoir les présentes
    Conditions de temps à autre, et ne manquerons pas de vous en aviser si nous
    procédons à des modifications significatives.
</p>
<h1>
    9. Droit applicable et juridictions compétentes
</h1>
<p>
    Les présentes Conditions seront régies par et interprétées conformément au
    droit belge.
</p>
<p>
    En cas de litige, seuls les tribunaux belges auront juridiction.
</p>
 </div>`,
  ranking_playing_as_text: "Votre Nom",
  ranking_select_text: "Nom de joueur",
  ranking_submit_button_text: "OK",
  ranking_input_field_label: "Votre nom",
  ranking_input_field_validate_succes: "Parfait",
  ranking_input_field_validate_error:
  "Le nom d'utilisateur doit être compris entre 3 et 12 lettres",
  ranking_choose_new_username_text: "Nouveau Nom",
  ranking_table_header_ranking: "Classement",
  ranking_table_header_username: "Nom du joueur",
  ranking_table_header_points: "Points",
  username_dialog_title: "Indiquez votre nom d'utilisateur pour marquer des points",
  username_dialog_submit_text: "Jouer",
  username_dialog_skip_text: "Passer",
  footer_powered_by: "Triscan",
  api_error_random_image: "Erreur au chargement de l'image aléatoire",
  api_error_upload_image: "Erreur eu chargement de la photo",
  api_error_processing_image: "L'image sélectionnée n'a pas été traitée car elle pourrait contenir du contenu obscène/personnellement identifiable.",
  api_error_report_mistake: "Echec de l'envoi du rapport",
  api_error_validate_guess: "Echec à l'envoi de votre réponse",
};





