import axios from "axios";
import { blobToBase64 } from "./utils";

axios.defaults.params = {};
axios.defaults.params["key"] = process.env.REACT_APP_API_KEY;
axios.defaults.headers = {
  "Content-Type": "application/json",
};

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

export const updateFraction = async (image_id, fraction) => {
  const result = await axios.put(`/update_fraction`, {
    image_id,
    fraction,
  });
  return result;
};

export const createImage = async (image, username) => {
  return blobToBase64(image).then((base64_image) => {
    base64_image = base64_image.substring(base64_image.indexOf(",") + 1);
    const result = axios.post(`/create_image`, {
      base64_image,
      ...(username && { username }),
    });

    return result;
  });
};

export const validate = async (image_id, user_input, username) => {
  const content = {
    image_id,
    guessed_fraction: user_input,
    ...(username && { username }),
  };

  const result = await axios.post(`/validate_guess`, content);
  return result;
};

export const getNextImage = async () => {
  const result = await axios.get(`/get_next_image`);
  return result;
};

export const reportMistake = async (image_id, guessed_fraction, username) => {
  const result = await axios.post("/report_mistake", {
    image_id,
    guessed_fraction,
    ...(username && { username }),
  });
  return result;
};

export const get_ranking = async () => {
  const result = await axios.get("get_ranking");
  return result;
};

export const create_user = async (username) => {
  const result = await axios.post("create_user", {
    username,
  });
  return result;
};
