import React, { useState, useContext } from "react";
import RankingList from "../atomic/RankingList";
import { create_user } from "../api/api";
import { GameContext } from "../contexts/GameContext";
import UsernameSelection from "../atomic/UsernameSelection";
import Button from "../atomic/Button";
import { validateUsername } from "../validation/username";
import { LanguageContext } from "../contexts/LanguageContext";

const Me = ({ createNewUser }) => {
  const { user, setUser } = useContext(GameContext);
  const { texts } = useContext(LanguageContext);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(undefined);

  const selectUsername = () => {
    createNewUser(username);
    setUser({ username });
  };

  const validateAndUpdateUsername = (username) => {
    setUsername(username);
    setUsernameError(validateUsername(username).error);
  };

  if (!user?.username) {
    return (
      <div className="w-full ">
        <div className="flex-col">
          <div className="flex">
            <UsernameSelection
              username={username}
              setUsername={validateAndUpdateUsername}
              error={usernameError}
              className="w-full"
              confirmButtonRender={() => (
                <Button
                  className="self-end"
                  onClick={selectUsername}
                  disabled={username.length === 0 || usernameError}
                >
                  {texts.ranking_submit_button_text}
                </Button>
              )}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full p-4">
        <div className="flex">
          <div className="mb-2 flex-grow  self-center">
            {texts.ranking_playing_as_text} <span className="text-md"></span>
            <span className="bg-green-100 text-[#80986F] md:text-lg text-sm font-semibold mr-3 px-3.5 py-1 rounded dark:bg-[#F2FDEA] dark:text-green-900">
              {user?.username}
            </span>
          </div>
          <Button
            onClick={() => {
              setUser({});
              setUsername("");
            }}
          >
            {texts.ranking_choose_new_username_text}
          </Button>
        </div>
      </div>
    );
  }
};

const Ranking = () => {
  const { ranking, syncRanking } = useContext(GameContext);

  const createNewUser = (username) => {
    create_user(username).then(() => {
      syncRanking();
    });
  };

  return (
    <div className="container max-w-2xl m-auto flex-grow h-full">
      <div className="flex flex-col gap-4 my-4">
        <Me createNewUser={createNewUser} />
        {ranking && <RankingList ranking={ranking} />}
      </div>
    </div>
  );
};

export default Ranking;
