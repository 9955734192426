import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";

const PredictedFractionText = ({ name }) => {
  const { texts } = useContext(LanguageContext);
  const FRACTIONS = {
    PMD: texts.PMD,
    PAPER: texts.PAPER,
    GLASS: texts.GLASS,
    GFT: texts.GFT,
    KGA: texts.KGA,
    REST: texts.REST,
    NO_WASTE: texts.NO_WASTE,
    OTHER: texts.OTHER,
  };
  return <>{FRACTIONS[name]}</>;
};

export default PredictedFractionText;
