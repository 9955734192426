import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import GoodAnswerSVG from "../images/good_answer.svg";
import ImagePicker from "../components/ImagePicker";
import RandomImagePicker from "../components/RandomImagePicker";
import { GameContext } from "../contexts/GameContext";
import FractionPill from "../components/FractionPill";
import { useNavigate } from "react-router";
import PredictedFractionText from "./PredictedFractionText";

const GameFlowGoodAnswer = () => {
  const { result } = useContext(GameContext);
  const navigate = useNavigate();
  const languageContext = useContext(LanguageContext);

  return (
    <>
      <div className="GameFlow_Result drop-shadow-lg rounded-lg p-8 bg-white flex flex-col text-center">
        <img
          src={GoodAnswerSVG}
          alt="Good answer emoji"
          className="max-w-md w-52 self-center "
        />
        <h1 className="text-center font-black text-3xl  text-slate-800 py-4 max-w-lg mx-auto">
          {languageContext.texts.good_answer_title}
        </h1>
        <div className="GameFlow_Result_Desc max-w-sm self-center">
          <div>{languageContext.texts.good_answer_text}</div>

          <div>
            <span>
              {result?.guessed_fraction && (
                <PredictedFractionText name={result?.guessed_fraction} />
              )}
            </span>
            <FractionPill name={result?.guessed_fraction} />
          </div>

          <div>{languageContext.texts.call_to_action}</div>
        </div>
      </div>
      <div className="GameFlow_Result_Actions  grid grid-cols-2 gap-4 ">
        <ImagePicker
          className="btn-primary text-center"
          routeToPlay={() => navigate("/play")}
        >
          <span>{languageContext.texts.upload_button_text}</span>
        </ImagePicker>
        <RandomImagePicker
          className="btn-primary"
          routeToPlay={() => navigate("/play")}
        >
          <span>{languageContext.texts.random_button_text}</span>
        </RandomImagePicker>
      </div>
    </>
  );
};

export default GameFlowGoodAnswer;
