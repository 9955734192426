import React, { useContext, useEffect, useState } from "react";
import { GameContext } from "../contexts/GameContext";
import ImageFeedback from "../components/ImageFeedback";
import { useNavigate } from "react-router";
import { validate } from "../api/api";
import Dialog from "../components/Dialog";
import toast from "react-hot-toast";
import { LanguageContext } from "../contexts/LanguageContext";

const GameUserInput = () => {
  const navigate = useNavigate();
  const { image, setResult, user, syncRanking } = useContext(GameContext);
  const { texts } = useContext(LanguageContext);

  const [isValidating, setIsValidating] = useState(false);

  useEffect(() => {
    setResult({});
  }, []);

  const submitFeedback = (selectedFraction) => {
    setIsValidating(true);
    validate(image?.image_id, selectedFraction, user?.username)
      .then((result) => {
        setResult({ ...result.data });
        setIsValidating(false);
        syncRanking();
        navigate("/result");
      })
      .catch(() => {
        toast.error(texts.api_error_validate_guess);
        setIsValidating(false);
      });
  };

  return (
    <Dialog>
      <div className="GameFlow__GameCard drop-shadow-lg rounded-lg py-8 px-4 bg-white">
        {image && (
          <ImageFeedback
            image={image?.base64_image}
            submitFeedback={submitFeedback}
            loading={isValidating}
          />
        )}
      </div>
    </Dialog>
  );
};

export default GameUserInput;
