import React, { useContext, useState } from "react";
import { GameContext } from "../contexts/GameContext";
import { getNextImage } from "../api/api";
import LoadingSpinner from "../atomic/LoadingSpinner";
import toast from "react-hot-toast";
import { LanguageContext } from "../contexts/LanguageContext";

const RandomImagePicker = ({ children, className, routeToPlay }) => {
  const { setImage } = useContext(GameContext);

  const { texts } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);

  const getRandomImage = () => {
    setLoading(true);
    getNextImage()
      .then((result) => {
        setImage(result.data);
        setLoading(false);
        routeToPlay();
      })
      .catch(() => {
        toast.error(texts.api_error_random_image);
        setLoading(false);
      });
  };

  return (
    <button className={className} onClick={getRandomImage}>
      {loading && <LoadingSpinner />}
      {children}
    </button>
  );
};

export default RandomImagePicker;
