import React, { useContext } from "react";
import { GameContext } from "../contexts/GameContext";
import { LanguageContext } from "../contexts/LanguageContext";
import { getCurrentUser } from "../filters/user";

const RankingList = ({ ranking }) => {
  const { user } = useContext(GameContext);
  const { texts } = useContext(LanguageContext);
  const amountOfRecords = 10;

  const ranking_top_ten = ranking.slice(0, amountOfRecords);
  let myUser = getCurrentUser();
  const inTopTen =
    ranking_top_ten.find((p) => p.username === user?.username) !== undefined;

  const formatPoints = (points) => {
    if (points) {
      return points.toLocaleString("en-US").replaceAll(",", ".");
    }
  };

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="relative overflow-x-auto flex flex-col gap-10">
        <table className="w-full  shadow-md sm:rounded-lg  text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                {texts.ranking_table_header_ranking}
              </th>
              <th scope="col" className="px-6 py-3">
                {texts.ranking_table_header_username}
              </th>
              <th scope="col" className="px-6 py-3">
                {texts.ranking_table_header_points}
              </th>
            </tr>
          </thead>
          <tbody>
            {ranking_top_ten.map((rank) => {
              if (user?.username === rank.username) {
                return (
                  <tr className="border-b bg-blue-100" key={rank.username}>
                    <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {rank.ranking}
                    </td>
                    <td className="px-6 py-4">{rank.username}</td>
                    <td className="px-6 py-4">{formatPoints(rank.points)}</td>
                  </tr>
                );
              }
              return (
                <tr
                  className="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
                  key={rank.username}
                >
                  <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {rank.ranking}
                  </td>
                  <td className="px-6 py-4">{rank.username}</td>
                  <td className="px-6 py-4">{formatPoints(rank.points)}</td>
                </tr>
              );
            })}
            {!inTopTen && myUser && (
              <tr
                className="text-sm text-left text-gray-500 dark:text-gray-400 bg-blue-100"
                key={myUser?.username}
              >
                <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  {myUser?.ranking}
                </td>
                <td className="px-6 py-4">{myUser?.username}</td>
                <td className="px-6 py-4">{formatPoints(myUser?.points)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RankingList;
