export const NL = {
  key: "NL",
  app_name: "SortScan",
  header_navigation_home: "Home",
  header_navigation_about: "Over",
  header_navigation_ranking: "Rangschikking",
  home_title: "Versla onze AI en help het milieu!",
  home_text: `Kan artificiële intelligentie (AI) ons helpen huishoudelijke verpakkingen beter te sorteren?  
   Daarvoor hebben we jouw hulp nodig! Hoe? Door onze AI beter te trainen en ons te vertellen waar het goed of fout gaat!
  Speel je mee?
   Bedankt om alleen lege verpakkingen op te laden.`,
  upload_button_text: "Neem een foto",
  random_button_text: "Controleer bestaande",
  report_button_text: "Fout melden",
  report_success_text: "Succesvol gemeld",
  under_development_text: "Momenteel nog niet beschikbaar",
  PMD: "PMD",
  PAPER: "Papier-Karton",
  GLASS: "Glas",
  GFT: "GFT",
  KGA: "Klein Gevaarlijk Afval",
  REST: "Restafval",
  OTHER: "Ander",
  NO_WASTE: "Geen afval",
  validating_guess_text: "We valideren je gok...",
  good_answer_title: "Geweldig! Je bent een expert in sorteren!",
  good_answer_text: "Je voorspelde dezelfde uitkomst als onze AI.",
  call_to_action:
    "Upload je eigen foto, of speel verder met foto's van de gemeenschap!",
  wrong_answer_title: "Oh, het lijkt erop dat onze AI het niet met je eens is!",
  wrong_answer_text_before_fraction:
    "Onze AI voorspelde een andere uitkomst. Het juiste antwoord was",
  wrong_answer_text_after_fraction:
    "Denk je dat we een fout hebben gemaakt? Rapporteer, of speel gewoon verder.",
  about_section: `<div>
   <h1>Wat is Sortscan ?</h1>
   <p>
     Sortscan is een experimentele toepassing ontworpen om ons voorspellingsmodel te trainen en te verbeteren. 
 Met jouw hulp kunnen we het verbeteren en recycleren hierdoor nog beter maken.
 
 <a href="/privacy">Privacyverklaring</a> - <a href="/terms_of_use">Gebruiksvoorwaarden</a>	
   </p>
 </div>
 <div>
   <h1>Spelregels</h1>
   <p>
     De regels zijn simpel, je krijgt punten voor elke actie die je uitvoert:</br>
   500 punten per correct antwoord</br> 
   100 punten per upload</br>
   200 punten per correctie</br>
   50 punten per poging</br>
      </p>
 </div>`,
  privacy_html: `<div>
<p>
    Privacyverklaring Sorteerscan.be van Fost Plus
</p>
<h1>
    1. Voorwerp en doel
</h1>
<p>
    Fost Plus respecteert uw privacy en handelt daarom altijd in
    overeenstemming met de geldende voorschriften inzake gegevensbescherming
    (inclusief de Algemene Verordening Gegevensbescherming – AVG).
</p>
<p>
    Deze Privacyverklaring bevat meer informatie over hoe de persoonsgegevens
    van de gebruikers worden verzameld, gebruikt en verwerkt door de app die
    beschikbaar is in de appstore (‘App’) dan wel als website, onder de
    verantwoordelijkheid van Fost Plus, en of ze aan derden worden
    overgedragen.
</p>
<p>
    Door ons uw persoonsgegevens te verstrekken, erkent u dat u op de hoogte
    bent van de volgende voorwaarden waaronder Fost Plus persoonsgegevens
    verwerkt en overdraagt.
</p>
<p>
    Als u ons de persoonsgegevens van andere individuen verstrekt, garandeert u
    dat (i) de betrokkene van deze privacyverklaring op de hoogte is gebracht,
    en dat (ii) voor zover voor een bepaalde verwerking toestemming vereist is,
    u de passende toestemming van de betrokkene voor die verwerking door Fost
    Plus hebt gekregen.
</p>
<p>
    Meer informatie over het gebruik van cookies vindt u in ons Cookiebeleid.
</p>
<h1>
    2. Verwerkingsverantwoordelijke
</h1>
<p>
    De verwerkingsverantwoordelijke is: Fost Plus vzw (vereniging zonder
    winstoogmerk)
</p>
<p>
    Maatschappelijke zetel: Olympiadenlaan 2 Avenue des Olympiades, 1140
    Brussel
</p>
<p>
    Ondernemingsnummer: 0447.550.872
</p>
<p>
    Telefoonnummer: 0032 (0) 2 775 02 50
</p>
<p>
    E-mailadres: <a href="mailto:privacy@fostplus.be">privacy@fostplus.be</a>
</p>
<h1>
    3. Persoonsgegevens, doeleinden en rechtsgronden
</h1>
<p>
    Fost Plus verzamelt en verwerkt verschillende categorieën persoonsgegevens
    over u (of de respectieve betrokkene). Dat kan onder andere doordat u het
    onlineregistratieformulier voor de app invult, doordat u de app gebruikt,
    doordat u uw locatie registreert tijdens een evenement waar de app actief
    is, doordat u Circulaire UCoins inwisselt voor waardebonnen of doordat u
    met Fost Plus contact opneemt via het contactformulier op de website of via
    e-mail.
</p>
<p>
    In onderstaande tabel staat welke persoonsgegevens wij verwerken, met welk
    doel en wat de toepasselijke rechtsgrond is.
</p>
<table border="1" cellspacing="0" cellpadding="0">
    <tbody>
        <tr>
            <td width="200" valign="top">
                <p>
                    <strong>Doel</strong>
                    <strong></strong>
                </p>
            </td>
            <td width="200" valign="top">
                <p>
                    <strong>Verwerkte persoonsgegevens</strong>
                    <strong></strong>
                </p>
            </td>
            <td width="200" valign="top">
                <p>
                    <strong>Rechtsgrond</strong>
                    <strong></strong>
                </p>
            </td>
        </tr>
        <tr>
            <td width="200" valign="top">
                <p>
                    <strong>
                        Herkennen van een gedeelde foto op vlak van
                        afvalfractie
                    </strong>
                </p>
            </td>
            <td width="200" valign="top">
                <p>
                    Geen directe persooonsgegevens al kan de foto indirecte
                    persoonsgegevens bevatten waarvan de gebruiker afstand
                    heeft gedaan
                </p>
                <p>
                    Deze foto kan publiek gebruikt worden
                </p>
            </td>
            <td width="200" valign="top">
                <p>
                    Noodzakelijk om op uw verzoek een overeenkomst (d.w.z. de
                    gebruiksvoorwaarden van de app/website) uit te voeren.
                </p>
            </td>
        </tr>
        <tr>
            <td width="200" valign="top">
                <p>
                    <strong>Informatie opvragen</strong>
                </p>
            </td>
            <td width="200" valign="top">
                <p>
                    Indien een aanvraag niet automatisch herkend kan worden dan
                    is het mogelijk dat de gebruiker vraagt om op de hoogte
                    gehouden te worden van een manuele validatie
                </p>
                <p>
                    Voornaam
                </p>
                <p>
                    Achternaam
                </p>
                <p>
                    Email
                </p>
                <p>
                    postcode
                </p>
            </td>
            <td width="200" valign="top">
                <p>
                    Mits toestemming
                </p>
            </td>
        </tr>
        </tbody>
</table>
<p>
    Bij elk gebruik van de app kunnen automatisch verschillende
    persoonsgegevens en logbestanden over u worden verzameld, waaronder uw
    IP-adres, de datum en de duur van uw gebruik van de app, uw browser en uw
    besturingssysteem. Die gegevens worden uitsluitend gebruikt voor
    statistische doeleinden en om de werking en het gebruik van de app te
    beheren, analyseren en optimaliseren in overeenstemming met onze
    gerechtvaardigde belangen bij het verbeteren van de app en onze diensten.
    Dergelijke gegevens worden in principe niet langer dan een jaar bewaard.
</p>
<p>
    De app/website heeft toegang nodig tot de camera van uw toestel om foto's
    van afval te kunnen maken of om de barcodes van afval te scannen. Wij
    bevelen gebruikers aan om geen andere persoonsgegevens over de gebruiker
    zelf en andere natuurlijke personen, vooral geen speciale categorieën
    gegevens, te onthullen bij het opladen van foto's naar de app/website.
    Wanneer een gebruiker dergelijke informatie over zichzelf of anderen
    oplaadt, is de gebruiker verantwoordelijk voor het garanderen van een
    rechtsgrond voor het openbaar maken van dergelijke informatie.
</p>
<h1>
    4. Doorgifte aan derden
</h1>
<p>
    Om de hierboven vastgelegde doeleinden te kunnen bereiken, doet Fost Plus
    een beroep op externe dienstverleners, bijvoorbeeld IT-dienstverleners.
    Indien nodig kunnen uw persoonsgegevens ook aan deze externe
    dienstverleners worden doorgegeven zodat zij hun taak kunnen uitvoeren. In
    dat geval verwerken zij uw persoonsgegevens uitsluitend in overeenstemming
    met de instructies van Fost Plus en met de toepasselijke voorschriften.
</p>
<p>
    Fost Plus kan ook bepaalde persoonsgegevens aan externe partijen doorgeven
    om te verwerken voor hun eigen doeleinden met hun eigen middelen. In dat
    geval treden die derden als verwerkingsverantwoordelijke op onder de
    toepasselijke wetgeving inzake gegevensbescherming. Dit omvat de doorgifte
    aan bevoegde autoriteiten in het kader van een dringende of lopende
    gerechtelijke procedure, in het kader van een gerechtelijk onderzoek en
    verzoeken om informatie, op verzoek van de regering of om aan andere
    wettelijke verplichtingen te voldoen.
</p>
<h1>
    5. Doorgifte naar landen buiten de EER
</h1>
<p>
    Wij geven uw gegevens momenteel niet door aan partijen in landen buiten de
    Europese Economische Ruimte (EER). Mocht dat in de toekomst veranderen, dan
    zullen wij ervoor zorgen dat uw persoonsgegevens alleen worden doorgegeven
    aan partijen die een passend niveau van gegevensbescherming toepassen
    (bijvoorbeeld door de zogenaamde modelcontractbepalingen van de Europese
    Unie in de overeenkomst met de ontvanger op te nemen mits een passende
    risicobeoordeling).
</p>
<h1>
    6. Uw rechten als betrokkene
</h1>
<p>
    Als betrokkene hebt u de volgende rechten:
</p>
<p>
    <strong>Recht van toegang</strong>
    – U hebt het recht om uw persoonsgegevens die wij verwerken te raadplegen,
    alsook het recht om een kopie van die persoonsgegevens te krijgen (behalve
    voor bepaalde uitzonderingen).
</p>
<p>
    <strong>Recht op gegevensoverdraagbaarheid</strong>
    – Onder bepaalde voorwaarden hebt u het recht om de door u verstrekte
    persoonsgegevens te ontvangen in een gestructureerd, algemeen gebruikt en
    machineleesbaar formaat, zodat u die gegevens aan een andere partij kunt
    overdragen.
</p>
<p>
    <strong>Recht op rectificatie en wissing </strong>
    – U hebt te allen tijde de mogelijkheid om uw gegevens kosteloos door ons
    te laten corrigeren of verwijderen, mits aan de wettelijke voorwaarden is
    voldaan. Gegevens die wij nodig hebben om een rechtsvordering in te
    stellen, uit te oefenen of te onderbouwen of die wij wettelijk verplicht
    zijn te bewaren, zijn uitgesloten van het recht op verwijdering.
</p>
<p>
    <strong>Beperking van de verwerking</strong>
    – Mits aan de geldige wettelijke voorwaarden is voldaan, kunt u eisen dat
    wij de verwerking van uw persoonsgegevens beperken.
</p>
<p>
    <strong>Recht van bezwaar</strong>
    – U kunt te allen tijde bezwaar maken tegen de verwerking van uw
    persoonsgegevens voor directmarketingdoeleinden of tegen verwerking die
    berust op ons gerechtvaardigd belang als rechtsgrond. Wanneer u bezwaar
    maakt tegen het verwerken van persoonsgegevens voor
    directmarketingdoeleinden, hoeft u ons hiervoor geen reden te geven.
</p>
<p>
    <strong>Intrekking van uw toestemming</strong>
    – Als wij uw persoonsgegevens verwerken op basis van uw toestemming, hebt u
    het recht om die toestemming te allen tijde in te trekken. Die intrekking
    heeft echter geen invloed op de rechtmatigheid van de verwerking van uw
    persoonsgegevens voor de periode voorafgaand aan het moment van die
    intrekking en verwerkingsactiviteiten die op een andere rechtsgrond zijn
    gebaseerd.
</p>
<p>
    <strong>Klacht bij de bevoegde autoriteiten</strong>
    – U hebt te allen tijde het recht om contact op te nemen met de
    gegevensbeschermingsautoriteit in België en, indien van toepassing, een
    klacht in te dienen.
</p>
<p>
    Stuur een e-mail naar privacy@fostplus.be om bovenstaande rechten uit te
    oefenen. Deze e-mail moet uw volledige contactgegevens bevatten, alsook een
    middel om uw identiteit te verifiëren (bijvoorbeeld een kopie van de
    voorkant van uw identiteitskaart of rijbewijs). Fost Plus kan echter
    verzoeken weigeren die naar eigen mening buitensporig zijn of een misbruik
    van de respectieve wet vormen.
</p>
<h1>
    7. Uw gegevens opslaan
</h1>
<p>
    Fost Plus bewaart uw persoonsgegevens in principe maar zolang als nodig is
    voor de hierboven beschreven doeleinden (rekening houdend met de
    toepasselijke wettelijke verjaringstermijnen). Zo worden bijvoorbeeld
    persoonsgegevens in vragen die u ons stelt in principe slechts twee jaar
    bewaard na ontvangst van de vraag. De gegevens die wij verwerken – voor
    zover u dat wenst – om u op de hoogte te houden van onze initiatieven enz.
    worden echter bijgewerkt en verwerkt zolang u geen bezwaar maakt tegen het
    ontvangen van nieuwsbrieven, (digitale) marketing of andere informatie.
    Persoonsgegevens die zijn gekoppeld aan contractuele documenten (bv.
    identificatie- of transactiegegevens) worden bewaard zolang uw account op
    de Click actief is en voor een maximale periode van tien jaar na
    beëindiging van de overeenkomst met Fost Plus, behalve voor wettelijke
    uitzonderingen. Na die periodes worden de gegevens verwijderd.
</p>
<p>
    Uw account blijft actief tot u verzoekt om hem te deactiveren of te
    verwijderen.
</p>
<h1>
    8. Wijzigingen
</h1>
<p>
    Fost Plus behoudt zich het recht voor om de Privacyverklaring te allen
    tijde te wijzigen, onder andere om ze aan wijzigingen in de aangeboden
    diensten of wijzigingen aan de wettelijke en/of regelgevende vereisten aan
    te passen. Wijzigingen aan deze Privacyverklaring treden in werking op het
    moment van publicatie. Wij raden u aan altijd de recentste versie te
    raadplegen. Deze Privacyverklaring is gepubliceerd op 25 juli 2020 en
    laatst geüpdatet op 25 juni 2021.
</p>
<h1>
    9. Contactgegevens van de functionaris voor gegevensbescherming en de
    gegevensbeschermingsautoriteit
</h1>
<p>
    Voor vragen over de verwerking van uw persoonsgegevens of het uitoefenen
    van uw rechten als betrokkene kunt u te allen tijde contact opnemen met
    onze Functionaris voor Gegevensbescherming (FG). Onze FG is onder andere
    aangesteld om specifiek als contactpersoon op te treden voor personen van
    wie wij de gegevens verwerken.
</p>
<p>
    U kunt met onze FG contact opnemen via e-mail (privacy@fostplus.be),
    telefonisch op +32 2775 03 50 of door een brief te sturen naar: Fost Plus
    Data Protection Manager, Olympiadenlaan 2 Avenue des Olympiades, 1140
    Brussel, België
</p>
<p>
    U kunt met vragen of klachten ook terecht bij de Belgische
    Gegevensbeschermingsautoriteit (
    <a href="https://www.dataprotectionauthority.be/">
        https://www.dataprotectionauthority.be/
    </a>
    ).
</p>
 </div>`,
  terms_of_use_html: `<div>
<p>Gebruiksvoorwaarden &ndash; Sorteerscan.be van Fost Plus</p>
<h1>1. Algemene informatie</h1>
<p>Dit zijn de gebruiksvoorwaarden van de &lsquo;Sorteerscan.be app die wij aanbieden (&lsquo;<strong>App</strong>&rsquo;). De App wordt beheerd door of namens Fost Plus vzw/asbl (<strong>wij, ons </strong>en <strong>onze</strong>). Wij zijn een vereniging zonder winstoogmerk, gevestigd in Belgi&euml;, met ondernemingsnummer 0447.550.872, en onze maatschappelijke zetel is gevestigd aan de Olympiadenlaan 2 Avenue des Olympiades, 1140 Brussel, Belgi&euml;.</p>
<p>Wij blijven te allen tijde eigenaar van de App en verlenen licenties voor het gebruik van de App en de diensten die u via de App worden aangeboden op basis van deze gebruiksvoorwaarden (&lsquo;<strong>Voorwaarden</strong>&rsquo;). Door op het registratiescherm van de App aan te geven dat u met deze Voorwaarden akkoord gaat, erkent u deze Voorwaarden te hebben gelezen en ze te aanvaarden en gaat u ermee akkoord de App alleen in overeenstemming met deze Voorwaarden te gebruiken. Als u niet akkoord gaat met de inhoud van de Voorwaarden, gebruik dan de App niet verder.</p>
<p>Het gebruik van uw persoonsgegevens die in of via de App zijn ingegeven, is onderworpen aan onze Privacyverklaring, waarin wordt uitgelegd hoe wij uw persoonsgegevens gebruiken.</p>
<p>Uw gebruik van de App kan ook onderhevig zijn aan de voorwaarden of de beleidsregels van een externe provider of operator via wiens site of platform u de App gedownload heeft (bv. browserprovider, appstore). Wij raden u aan deze voorwaarden en beleidsregels te lezen en na te leven.</p>
<p>Deze Voorwaarden zijn voor het laatst bijgewerkt op <strong><em>25 juni 2021</em></strong>. De laatste versie van deze Voorwaarden is altijd via de App beschikbaar. Het is uw verantwoordelijkheid om de toepasselijke Voorwaarden regelmatig na te lezen en de laatste versie na te leven.</p>
<h1>2. Doel en inhoud van de App</h1>
<p>Voor de toepassing van deze Voorwaarden verstaan wij onder afval: verpakkingen of afval van artikelen en goederen die buitenshuis zijn gebruikt (bv. op festivals of gelijkaardige evenementen) of verpakkingen die op openbare plaatsen worden weggegooid. Huishoudelijk afval is uitgesloten, mag niet worden opgeladen en telt niet mee.</p>
<p>Wij hebben de App gemaakt om circulariteit te stimuleren en mensen ertoe aan te zetten afval op te rapen en in de juiste vuilnisbak te deponeren. Door barcodes te scannen of een foto te maken van stukken afval trachten wij u van een sorteeradvies te voorzien</p>
<p>Door meer artikelen te scannen of foto's ervan te maken, vergroot u de databank, het gebruiksgemak en de kans op een juist sorteeradvies.</p>
<h1>3. Accounts</h1>
<p>U mag deze App alleen gebruiken als u ermee akkoord gaat om de App te openen en te gebruiken in overeenstemming met deze Voorwaarden.</p>
<p><strong>Als u jonger bent dan 16 jaar, moet u toestemming krijgen van een volwassene die effectief ouderlijk verantwoordelijk voor u is, voordat u de App opent en gebruikt.</strong></p>
<p>Om de App te gebruiken, moet u zich registreren en uw account bij ons instellen door het registratieformulier voor de account in de App in te vullen. U moet uw eigen naam en e-mailadres opgeven. U hoeft zich maar &eacute;&eacute;n keer te registreren. U moet ons juiste, nauwkeurige, volledige en recente informatie geven. U bent verantwoordelijk voor de informatie die u ons verstrekt.</p>
<p>Bij het registreren van een account bij ons wordt u gevraagd om een wachtwoord aan te maken. U moet uw wachtwoord altijd geheim houden en alleen gebruiken voor de toegang tot en het gebruik van uw account en niet voor enig ander doel. U bent de enige geautoriseerde gebruiker van uw account en daarom mag u uw wachtwoord niet aan iemand anders bekendmaken. U mag ook het e-mailadres of wachtwoord van iemand anders niet gebruiken. U begrijpt en gaat ermee akkoord dat wij niet aansprakelijk kunnen worden gesteld voor de gevolgen van het onwettig en ongeoorloofd gebruik van de App door het gebruik van uw gebruikersnaam en wachtwoord. U moet onmiddellijk contact met ons opnemen wanneer u ongeoorloofd gebruik van uw account ontdekt of een fout bij het gebruik van uw wachtwoord (zie onderstaande rubriek Communicatie voor meer informatie). U bent verantwoordelijk voor alle acties die via uw account worden uitgevoerd. Elke schending van deze voorwaarden en/of elk gebruik van uw account door iemand aan wie u uw wachtwoord hebt gegeven wordt gezien alsof de schending of het gebruik door u is gebeurd en ontslaat u niet van uw verplichtingen tegenover ons.</p>
<h1>4. Gebruik van de App</h1>
<h2>4.1.Uw gebruik</h2>
<p>Het gebruik van de App is gratis. U hebt alleen een internetverbinding nodig.</p>
<p>De App is alleen bedoeld voor niet-commercieel gebruik (tenzij door ons uitdrukkelijk schriftelijke toestemming is verleend voor ander gebruik) en alleen in overeenstemming met deze Voorwaarden. U gaat ermee akkoord dat uw gebruik van de App op 'as is'- en 'as available'-basis en op eigen risico is.</p>
<p>U begrijpt dat u, om de App correct te kunnen gebruiken, de App toegang moet geven tot:</p>
<ul>
<li><u>uw camera</u>: zodat u foto's kunt maken, barcodes kunt scannen. De App heeft alleen toegang tot de camera wanneer u de App gebruikt.</li>
</ul>
<p>Als u de App geen toegang tot uw locatie en camera geeft, zult u niet van de volledige functionaliteit van de App kunnen genieten.</p>
<p>Tenzij uitdrukkelijk vermeld in deze Voorwaarden, is het niet toegestaan om:</p>
<ul>
<li>inhoud te &lsquo;scrapen&rsquo; of inhoud van de App op een server die of een ander opslagapparaat dat met een netwerk is verbonden op te slaan of een elektronische databank te maken door systematisch alle inhoud van de App te downloaden en op te slaan en is het ook niet toegestaan om de App op een andere server of toepassing te &lsquo;spiegelen&rsquo;, zonder onze +voorafgaande schriftelijke toestemming;</li>
<li>inhoud in de App in te voeren die vals, misleidend of frauduleus is of die als lasterlijk, discriminerend, smadelijk, beledigend of anderszins aanstootgevend kan worden beschouwd of die inbreuk maakt op de rechten van derden;</li>
<li>inhoud van de App te verwijderen of wijzigen, of te proberen om beschermings-, beveiligings- of vertrouwelijkheidsmaatregelen te omzeilen, of de goede werking van de App of de servers waarop de App wordt gehost te verstoren;</li>
<li>de App en/of de inhoud die op de App beschikbaar wordt gesteld opnieuw te publiceren, opnieuw te verdelen, te reverse-engineeren, te exploiteren of opnieuw over te dragen; of</li>
<li>iets anders te doen dat niet uitdrukkelijk door deze Voorwaarden is toegestaan.</li>
</ul>
<p>U mag de App alleen voor wettelijk toegestane doeleinden gebruiken (in overeenstemming met alle toepasselijke wetten en voorschriften), op een verantwoorde manier, en niet op een manier die onze naam of reputatie of die van een van onze werknemers, agenten of vertegenwoordigers kan schaden.</p>
<p>Wij geven u persoonlijk het recht om de App te gebruiken zoals in deze Voorwaarden vastgelegd. U mag uw rechten of verplichtingen onder deze voorwaarden niet aan een andere persoon overdragen zonder onze voorafgaande schriftelijke toestemming. U gaat akkoord dat, indien u het toestel verkoopt waarop de App is ge&iuml;nstalleerd, u de App eerst dient te verwijderen.</p>
<h2>4.2. Opschorting van de werking van de App</h2>
<p>Wij kunnen de continue, ononderbroken of foutloze werking van de App niet garanderen. We kunnen van tijd tot tijd naar eigen goeddunken en met of zonder voorafgaande kennisgeving de werking van bepaalde functies, onderdelen of inhoud van de App (geheel of gedeeltelijk) tijdelijk opschorten voor herstellingen of onderhoud of om inhoud, functies of functionaliteiten te verwijderen, te wijzigen, bij te werken of te upgraden..</p>
<p>U gaat ermee akkoord dat wij tegenover u of enige derde partij, onderworpen aan de aansprakelijkheidsvoorwaarden die in de rubriek Garanties en aansprakelijkheid zijn vastgelegd, niet aansprakelijk zijn voor onbeschikbaarheid, wijziging, opschorting of verwijdering van de App of functies, onderdelen of inhoud van de App.</p>
<p>De App maakt verbinding met diensten via internet dat door uw externe internetprovider of het netwerk van uw mobiele serviceprovider wordt aangeboden. Een stabiele internetverbinding is vereist om toegang tot onze diensten te krijgen. U erkent en gaat ermee akkoord dat wij niet verantwoordelijk zijn voor onbeschikbaarheid van internet, problemen met de verbindingskwaliteit, onderbreking van de dienstverlening of verminderde functionaliteit in de App die u mogelijk ervaart als gevolg van een dergelijke onbeschikbaarheid van een deel van het internet of problemen met de internetverbinding van uw apparaat.</p>
<h2>4.3.Be&euml;indigen van onze samenwerking</h2>
<p>Wij kunnen, met of zonder voorafgaande kennisgeving, de contractuele samenwerking tussen u en ons of uw gebruik van uw account opschorten of be&euml;indigen als u een van deze Voorwaarden hebt geschonden.</p>
<p>Als u deze Voorwaarden hebt geschonden, kunnen wij acties ondernemen die wij passend achten. Een dergelijke inbreuk door u kan ertoe leiden dat wij, met of zonder kennisgeving, alle of een van de volgende acties ondernemen:</p>
<ul>
<li>u een waarschuwing geven;</li>
<li>door u ingevoerde inhoud onmiddellijk, tijdelijk of permanent verwijderen;</li>
<li>uw recht op toegang tot en gebruik van de App onmiddellijk, tijdelijk of permanent intrekken;</li>
<li>een gerechtelijke procedure tegen u om alle verhaalbare verliezen en schade als gevolg van de inbreuk te vergoeden; en/of</li>
<li>alle relevante informatie openbaar maken aan wetshandhavingsinstanties zoals wij redelijkerwijs nodig achten.</li>
</ul>
<p>De hierboven beschreven rechten zijn cumulatief en zijn niet beperkt en wij kunnen mogelijk elke andere actie ondernemen die we passend achten.</p>
<p>Bij be&euml;indigen van deze Voorwaarden of uw account, om welke reden dan ook, met of zonder rechterlijke tussenkomst:</p>
<ul>
<li>vervallen onmiddellijk alle rechten die onder deze Voorwaarden aan u zijn verleend;</li>
<li>moet u onmiddellijk alle gebruik van de App stoppen en de App vernietigen of verwijderen van alle (mobiele) toestellen in uw bezit.</li>
</ul>
<p>U kunt de contractuele samenwerking be&euml;indigen door ons te verzoeken uw account te deactiveren.</p>
<h1>5. Garanties en aansprakelijkheid</h1>
<p>Hoewel wij redelijke maatregelen nemen om ervoor te zorgen dat de App vrij is van malware en virussen, kunnen en zullen wij niet garanderen dat de App volledig vrij zal zijn van virussen en/of andere code die mogelijk besmettende of schadelijke elementen bevatten. Het is uw verantwoordelijkheid om de gepaste IT-beveiligingsmaatregelen te nemen (waaronder de installatie van updates van de App, het uitvoeren van antivirus- en andere beveiligingscontroles) om te voldoen aan uw specifieke vereisten met betrekking tot de veiligheid en betrouwbaarheid van de inhoud waartoe u via onze App toegang krijgt.</p>
<p>U begrijpt en gaat ermee akkoord dat, behalve in de gevallen waarvoor de wet niet toestaat dat aansprakelijkheid wordt uitgesloten, wij onder geen enkele omstandigheid (ongeacht de ernst van de fout), noch op directe of indirecte, noch op algemene of specifieke wijze, aansprakelijk kunnen worden gesteld voor enige directe of indirecte schade, waaronder onder andere schade als gevolg van winstderving, gegevensverlies, commercieel verlies, bedrijfsonderbreking, verlies van zakelijke perspectieven of enige andere commerci&euml;le schade of commercieel verlies als gevolg van (i) uw gebruik of het niet kunnen gebruiken van de App, (ii) wijzigingen aan de App of de stopzetting van de App, (iii) het verwijderen, beschadigen of niet opslaan van inhoud en andere gegevens die worden bewaard of overgedragen met behulp van de App, (iv) niet-naleving door externe handelaars van hun verplichtingen in het kader van een transactie tussen u en een dergelijke externe handelaar of (iv) overmacht.</p>
<p>U gaat ermee akkoord om ons volledig te vrijwaren van en tegen claims van derden die voortvloeien uit of verband houden met de schending van deze Voorwaarden.</p>
<p>Er is geen intentie tot of oprichting van een agentschap, partnerschap, joint venture, arbeidsrelatie of franchiserelatie tussen u en ons door uw gebruik van de App.</p>
<h1>6. Intellectuele-eigendomsrechten</h1>
<p>U erkent dat alle intellectuele-eigendomsrechten op alle inhoud van de App (waaronder tekst, illustraties, software, foto's en andere afbeeldingen, video's, geluid, handelsmerken en logo's) eigendom zijn van ons of onze licentiegevers.</p>
<p>Behalve zoals uitdrukkelijk hier vastgelegd of zoals u uitdrukkelijk schriftelijk door ons is toegestaan, geeft niets in deze Voorwaarden u rechten met betrekking tot enige intellectuele eigendom die eigendom is van ons of onze licentiegevers en u erkent dat u geen eigendoms- of gebruiksrechten verkrijgt door inhoud van de App te downloaden. Als u pagina's van de App afdrukt of er een schermafdruk van neemt, of ze kopieert of opslaat, moet u ervoor zorgen dat alle kennisgevingen over auteursrechten, handelsmerken of andere intellectuele-eigendomsrechten in de originele inhoud worden gereproduceerd.</p>
<p>De gebruiker is zelf verantwoordelijk om te controleren dat de informatie/foto&rsquo;s of dat die hij ter beschikking stelt via de app/website gevrijwaard is van enig intellectueel eigendomsrecht en/of geeft een onbeperkte eeuwigdurende licentie voor het gebruik van de foto/informatie. Ook ten aanzien van derden of het algemene publiek.</p>
<h1>7. Communicatie</h1>
<p>Alle kennisgevingen van u aan ons moeten schriftelijk gebeuren op het hieronder vermelde post- of e-mailadres. Wij kunnen kennisgevingen aan u naar het door u opgegeven e-mailadres sturen.</p>
<p>Richt vragen die u over deze Voorwaarden of de App hebt of klachten of bezorgdheden met betrekking tot de App of meld een probleem met de App via e-mail aan info@sorteerscan.be of per post naar: <em>Fost Plus, Olympiadenlaan 2 Avenue des Olympiades, 1140 Brussel, Belgi&euml;</em></p>
<h1>8. Wijzigingen</h1>
<p>Aangezien het ons beleid is om de App voortdurend te evalueren en bij te werken, kunnen wij deze Voorwaarden van tijd tot tijd herzien en wij brengen u op de hoogte als wij belangrijke wijzigingen aanbrengen.</p>
<h1>9. Toepasselijk recht en bevoegde rechtbanken</h1>
<p>Op deze Voorwaarden is het Belgisch recht van toepassing en de Voorwaarden dienen te worden ge&iuml;nterpreteerd in overeenstemming met het Belgisch recht.</p>
<p>Bij geschillen zijn enkel de rechtbanken van Belgi&euml; bevoegd.</p>
 </div>`,
  ranking_playing_as_text: "Spelen als",
  ranking_select_text: "Kies je gebruikersnaam",
  ranking_submit_button_text: "Selecteer",
  ranking_input_field_label: "Jouw gebruikersnaam",
  ranking_input_field_validate_succes: "Dat ziet er prima uit",
  ranking_input_field_validate_error:
    "Gebruikersnaam moet tussen 3 en 12 karakters zijn",

  ranking_choose_new_username_text: "Kies nieuw",
  ranking_table_header_ranking: "Rangschikking",
  ranking_table_header_username: "Gebruikersnaam",
  ranking_table_header_points: "Punten",
  username_dialog_title: "Vul je gebruikersnaam in om punten te scoren",
  username_dialog_submit_text: "Speel",
  username_dialog_skip_text: "Sla over",
  footer_powered_by: "SortScan",
  api_error_random_image: "Kan geen willekeurig beeld ophalen",
  api_error_upload_image: "Kan de geselecteerde afbeelding niet uploaden",
  api_error_processing_image: "De geselecteerde afbeelding is niet verwerkt omdat deze mogelijk obscene/persoonlijk identificeerbare inhoud bevat.",
  api_error_report_mistake: "Kan de fout niet rapporteren",
  api_error_validate_guess: "Kan je gok niet insturen",
};
