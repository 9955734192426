import React, { useEffect, useContext } from "react";
import Scanner from "./pages/Scanner";
import GameFlow from "./pages/GameUserInput";
import Ranking from "./pages/Ranking";
import About from "./pages/About";
import Container from "./Container";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GameContextProvider } from "./contexts/GameContext";
import { LanguageContext } from "./contexts/LanguageContext";
import "./App.css";
import GameUserResult from "./pages/GameUserResult";
import { EN } from "./config/EN";
import { NL } from "./config/NL";
import { FR } from "./config/FR";
import UserSelection from "./pages/UserSelection";
import Privacy from "./pages/Privacy";
import TermsOfUse from "./pages/TermsOfUse";

export const App = () => {
  const { texts, setTexts } = useContext(LanguageContext);

  useEffect(() => {
    const url = window.location.origin;

    let selectedLanguage = EN;

    if (url.includes("sorteerscan.be")) {
      selectedLanguage = NL;
    } else if (url.includes("triscan.be")) {
      selectedLanguage = FR;
    }

    setTexts(selectedLanguage);
  }, []);

  useEffect(() => {
    document.title = texts.app_name;
  }, [texts]);

  return (
    <GameContextProvider>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Container>
                <Scanner />
              </Container>
            }
          />
          <Route
            exact
            path="/about"
            element={
              <Container>
                <About />
              </Container>
            }
          />
          <Route
            exact
            path="/play"
            element={
              <Container>
                <GameFlow />
              </Container>
            }
          />
          <Route
            exact
            path="/user_selection"
            element={
              <Container>
                <UserSelection />
              </Container>
            }
          />
          <Route
            exact
            path="/result"
            element={
              <Container>
                <GameUserResult />
              </Container>
            }
          />
          <Route
            exact
            path="/ranking"
            element={
              <Container>
                <Ranking />
              </Container>
            }
          />

          <Route
            exact
            path="/privacy"
            element={
              <Container>
                <Privacy />
              </Container>
            }
          />

          <Route
            exact
            path="/terms_of_use"
            element={
              <Container>
                <TermsOfUse />
              </Container>
            }
          />
        </Routes>
      </BrowserRouter>
    </GameContextProvider>
  );
};
