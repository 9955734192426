import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";

const AnimatedCounter = ({ from, to, duration }) => {
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: duration,
      onUpdate(value) {
        node.textContent = Number(value.toFixed(0)).toLocaleString("en-US").replaceAll(",", ".");
      },
    });

    return () => controls.stop();
  }, [from, to]);

  return <p ref={nodeRef} />;
};

export default AnimatedCounter;
