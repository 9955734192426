/* This example requires Tailwind CSS v2.0+ */
import React, { useContext } from "react";
import { LanguageContext } from "./contexts/LanguageContext";
import LanguageSelector from "./components/LanguageSelector";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";
import UserAvatar from "./components/UserAvatar";
import classNames from "classnames";

export default function Header() {
  const languageContext = useContext(LanguageContext);

  const navigation = [
    { name: languageContext.texts.header_navigation_home, href: "/" },
    { name: languageContext.texts.header_navigation_about, href: "/about" },
    { name: languageContext.texts.header_navigation_ranking, href: "/ranking" },
  ];

  return (
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-[#578C37]">
        {({ open }) => (
          <>
            <div className="Home container sm-auto mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center w-full">
                  <div className="flex-shrink-0 font-black text-lg text-white">
                    {languageContext.texts?.app_name}
                  </div>
                  <div className="hidden md:block flex-grow">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? "bg-[#7fba5b] text-white"
                                : "text-[#7fba5b] hover:bg-[#7fba5b] hover:text-white",
                              "px-3 py-2 rounded-md text-sm font-medium"
                            )
                          }
                        >
                          {item.name}
                        </NavLink>
                      ))}
                      <LanguageSelector />
                    </div>
                  </div>
                  <UserAvatar className="hidden md:block" />
                </div>

                <div className="-mr-2 md:hidden flex gap-2">
                  <UserAvatar className=" self-center md:hidden" />

                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button className="block" key={item.name}>
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) =>
                        classNames(
                          isActive
                            ? "bg-[#7fba5b] text-white"
                            : "text-[#7fba5b] hover:bg-[#7fba5b] hover:text-white",
                          "px-3 py-2 rounded-md text-sm font-medium block w-full"
                        )
                      }
                    >
                      {item.name}
                    </NavLink>
                  </Disclosure.Button>
                ))}
              </div>
              <Disclosure.Button className="block px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <LanguageSelector />
              </Disclosure.Button>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
