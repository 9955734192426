import React, { useState, createContext, useEffect } from "react";
import { get_ranking } from "../api/api";

// Create Context Object
export const GameContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const GameContextProvider = (props) => {
  const [image, setImage] = useState({});
  const [result, setResult] = useState({});
  const [user, setUser] = useState({});
  const [ranking, setRanking] = useState([]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      setUser(user);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const syncRanking = () => {
    get_ranking().then((response) => {
      setRanking(Array.from(response.data));
    });
  };

  return (
    <GameContext.Provider
      value={{
        image,
        setImage,
        result,
        setResult,
        user,
        setUser,
        ranking,
        syncRanking,
      }}
    >
      {props.children}
    </GameContext.Provider>
  );
};
