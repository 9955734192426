export const validateUsername = (username) => {
  if (!username) {
    return {
      success: false,
      error: "username_empty",
    };
  }
  if (username.length < 3 || username.length > 12) {
    return {
      success: false,
      error: "username_length_error",
    };
  }

  return {
    success: true,
    error: "",
  };
};
