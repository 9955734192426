import React, { useContext, useEffect } from "react";
import AnimatedCounter from "../atomic/AnimatedCounter";
import usePrevious from "../hooks/previous";
import { UserCircleIcon, StarIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { getCurrentUser } from "../filters/user";
import { GameContext } from "../contexts/GameContext";
import { NavLink } from "react-router-dom";

const UserAvatar = React.memo(({ className }) => {
  const user = getCurrentUser();
  const { syncRanking } = useContext(GameContext);
  const wasUser = usePrevious(user);

  useEffect(() => {
    syncRanking();
  }, []);


  return (
    <div className={classNames("text-[#7fba5b]", className)}>
      <NavLink to="/ranking" className="flex gap-2">
        <div className="self-center">{user?.username}</div>
        <div className="self-center">-</div>
        <div className="self-center flex gap-1">
          <div>
            {user?.points > wasUser?.points ? (
              <AnimatedCounter
                from={wasUser?.points}
                to={user?.points}
                duration={2}
              />
            ) : (
              user &&
              Number(user.points.toFixed(0))
                .toLocaleString("en-US")
                .replaceAll(",", ".")
            )}
          </div>
          <StarIcon
            fill="#FFD38B"
            className="h-4 w-4 self-center text-[#FFD38B]"
          />
        </div>
        <UserCircleIcon className="h-8 w-8" />
      </NavLink>
    </div>
  );
});

UserAvatar.displayName = "UserAvatar";
export default UserAvatar;
