import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";

const TermsOfUse = () => {
  const languageContext = useContext(LanguageContext);

  return (
    <main className="container max-w-4xl mx-auto flex flex-col gap-4 m-4">
      <div
        className="customHtml"
        dangerouslySetInnerHTML={{
          __html: languageContext.texts.terms_of_use_html,
        }}
      ></div>
    </main>
  );
};

export default TermsOfUse;
