import React, { useState, createContext } from "react";

// Create Context Object
export const LanguageContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const LanguageContextProvider = (props) => {
  const [texts, setTexts] = useState({});

  return (
    <LanguageContext.Provider
      value={{
        texts,
        setTexts,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};
