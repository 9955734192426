import React from "react";
import { XCircleIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

const Dialog = ({ children, className }) => {
  return (
    <main
      className={classNames(
        "container max-w-4xl mx-auto flex flex-col gap-4 min-h-screen pt-4",
        className
      )}
    >
      <NavLink to="/">
        <XCircleIcon className="w-10 text-slate-400" />
      </NavLink>
      {children}
    </main>
  );
};

export default Dialog;
