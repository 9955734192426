import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import ImagePicker from "../components/ImagePicker";
import RandomImagePicker from "../components/RandomImagePicker";
import HomeImg from "../images/home_img.svg";
import { useNavigate } from "react-router";
import { getCurrentUser } from "../filters/user";

const Scanner = () => {
  const languageContext = useContext(LanguageContext);
  const navigate = useNavigate();
  const user = getCurrentUser();

  const routeToPlay = () => {
    if (!user) {
      navigate("/user_selection");
    } else {
      navigate("/play");
    }
  };

  return (
    <main className="Home container max-w-4xl py-8 sm-auto mx-auto px-4 ">
      <div className="max-w-lg mx-auto">
        <img src={HomeImg} alt="Healthy environment" className="w-full" />
      </div>

      <div className="Home_Desc pb-8">
        <h1 className="text-center font-black text-3xl  text-slate-800 py-4 max-w-lg mx-auto">
          {languageContext.texts.home_title}
        </h1>
        <p className="text-lg max-w-xl mx-auto">
          {languageContext.texts.home_text}
        </p>
      </div>

      <div className="button-actions flex gap-2 place-content-center">
        <ImagePicker className="btn-primary" routeToPlay={routeToPlay}>
          <span>{languageContext.texts.upload_button_text}</span>
        </ImagePicker>

        <RandomImagePicker className="btn-primary" routeToPlay={routeToPlay}>
          <span>{languageContext.texts.random_button_text}</span>
        </RandomImagePicker>
      </div>
    </main>
  );
};

export default Scanner;
