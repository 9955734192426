import React, { useContext } from "react";
import { LanguageContext } from "./contexts/LanguageContext";

const Footer = () => {
  const { texts } = useContext(LanguageContext);

  return (
    <div className="w-full bg-white p-3 px-4 text-sm text-gray-400 shadow-inner">
      ©2022 {texts.footer_powered_by}
    </div>
  );
};

export default Footer;
