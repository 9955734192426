import React from "react";
import classNames from "classnames";

const Button = ({ onClick, className, children, disabled }) => {
  return (
    <button
      className={classNames(
        "px-8 rounded-lg bg-[#7fba5b] text-slate-50 font-bold p-2 uppercase border-[#578C37] border",
        className,
        disabled && "cursor-not-allowed"
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
