import React from "react";
import gftSvg from "../images/fraction_icons/gft.svg";
import glasSvg from "../images/fraction_icons/glas.svg";
import pmdSvg from "../images/fraction_icons/pmd.svg";
import restSvg from "../images/fraction_icons/restafval.svg";
import kgaSvg from "../images/fraction_icons/kga.svg";
import paperSvg from "../images/fraction_icons/papierkarton.svg";
import nowasteSvg from "../images/fraction_icons/nowaste.svg";
import otherPng from "../images/fraction_icons/other.png";
import { FractionColors } from "../config/FractionColors";

const FRACTIONS_ICON = {
  PMD: pmdSvg,
  PAPER: paperSvg,
  GLASS: glasSvg,
  GFT: gftSvg,
  KGA: kgaSvg,
  REST: restSvg,
  NO_WASTE: nowasteSvg,
  OTHER: otherPng
};

const FractionPill = ({ name, className }) => {
  return (
    <span
      className={`inline-block w-10 h-10 ml-2 rounded-full ${className}`}
      style={{ backgroundColor: FractionColors[name] }}
    >
      <img src={FRACTIONS_ICON[name]} />
    </span>
  );
};

export default FractionPill;
