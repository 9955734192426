export const EN = {
  key: "EN",
  app_name: "SortScan",
  header_navigation_home: "Home",
  header_navigation_about: "About",
  header_navigation_ranking: "Ranking",

  home_title: "Beat our AI and help the environment!",
  home_text: `Can artificial intelligence (AI) help us sort household packaging better? 
   For that we need your help ! How ? By training our AI better and tell us where it's right or wrong !
   Will you play along?
   Please try to only upload empty packagings, thank you.`,
  upload_button_text: "Take picture",
  random_button_text: "Check existing",
  report_button_text: "Report mistake",
  report_success_text: "Successfully reported",
  under_development_text: "Coming soon",
  PMD: "PMD",
  PAPER: "Paper-Cardboard",
  GLASS: "Glass",
  GFT: "Organic Waste",
  KGA: "Household Chemical Waste",
  REST: "Residual Waste",
  OTHER: "Other",
  NO_WASTE: "No Waste",
  validating_guess_text: "Validating your guess ...",
  good_answer_title: "Great! You're an expert sorter!",
  good_answer_text: "You predicted the same outcome as our AI.",
  call_to_action:
    "Upload your own picture, or continue playing with pictures from the community!",
  wrong_answer_title: "Oh, it seems our AI disagrees with you!",
  wrong_answer_text_before_fraction:
    "Our AI predicted a different outcome. The right answer was",
  wrong_answer_text_after_fraction:
    "Do you think we made a mistake? Report, or simply continue playing.",
  about_section: `<div>
   <h1>What is Sortscan ?</h1>
   <p>
     Sortscan is an experimental application designed to train and improve our prediction model. 
 With your help we can improve it and make recycling even better.
 
 <a href="/privacy">Privacy Statement</a> - <a href="/terms_of_use">Terms of use</a>	
   </p>
 </div>
 <div>
   <h1>Gameplay rules</h1>
   <p>
     The rules are simple, you gain points for each action you perform:</br>
   500 points per good answer</br> 
   100 points per upload</br>
   200 points per correction</br>
   50 points per attempt</br>
      </p>
 </div>`,
  privacy_html: `<div>
  <p>Privacy Statement SortScan- by Fost Plus</p>
<h1>1. Object and purpose</h1>
<p>Fost Plus respects your privacy and therefore always acts in accordance with the applicable regulations on data protection (including the General Data Protection Regulation - GDPR).</p>
<p>This Privacy Statement contains more information on how the personal data of users are collected, used and processed the app available in your appstore ("App" or "the website"), under the responsibility of Fost Plus and, whether they are transferred to third parties.</p>
<p>By providing your personal data to us, you acknowledge that you are aware of the following terms and conditions under which Fost Plus processes and transfers personal data.</p>
<p>If you provide us with the personal data of other individuals, you guarantee that (i) the data subject was informed of this Privacy Statement, and that; (ii) insofar as certain processing requires consent, you have obtained the relevant consent from the data subject for this processing by Fost Plus.</p>
<p>More information about the use of cookies can be found in our Cookie Policy.</p>
<h1>2. Data Controller</h1>
<p>The data controller is: Fost Plus vzw (non-profit organisation)</p>
<p>Registered office: Olympiadenlaan 2 Avenue des Olympiades, 1140 Brussels</p>
<p>Business number: 0447.550.872</p>
<p>Telephone: 0032 (0) 2 775 02 50</p>
<p>E-mail: privacy@fostplus.be</p>
<h1>3. Personal data, purposes and legal grounds</h1>
<p>Fost Plus collects and processes different categories of personal data concerning you (or the relevant data subject). This may be done, among other things, by filling out the online registration form for the App, by using the App, by registering your location during an event where the App is active, by exchanging Circular UCoins for vouchers or by contacting Fost Plus by using the contact form available via the Website or via e-mail.</p>
<p>The table below lists what personal data we process, for which purpose and the applicable legal ground.</p>
<table>
<tbody>
<tr>
<td width="200">
<p><strong>Purpose</strong></p>
</td>
<td width="200">
<p><strong>Processed personal data</strong></p>
</td>
<td width="200">
<p><strong>Legal ground</strong></p>
</td>
</tr>
<tr>
<td width="200">
<p><strong>Determine fraction with the help of image recognition</strong></p>
</td>
<td width="200">
<p>No direct personal information although the image may contain indirectly user or anyone else personal data</p>
<p>Identification data (name, e-mail, password)</p>
</td>
<td width="200">
<p>Necessary in order to process your request</p>
</td>
</tr>
<tr>
<td width="200">
<p><strong>Request for more information</strong></p>
</td>
<td width="200">
<p>In case a request cannot be processed automatically, it is possible for the user to be informed after placing request a manual validation</p>
<p>First Name</p>
<p>Last Name</p>
<p>Email address</p>
<p>Postal code</p>
</td>
<td width="200">
<p>with authorization</p>
</td>
</tr>
</tbody>
</table>
<p>Upon every use of the application/website, various personal data and log files about you may be collected automatically, including your IP address, the date and length of your use of the App, your browser and your operating system. These data are used exclusively for statistical purposes and to monitor, analyse and optimise the working and use of the application/website, in accordance with our legitimate interest in improving the App and our services. Such data are in principle not kept for longer than one year.</p>
<p>The application/website needs access to the camera of your device in order to allow you to take pictures of litter or to scan the barcodes of litter. Users are encouraged not to reveal other personal data concerning the user itself and other natural persons, especially no special categories of data, when uploading pictures to the application/website. When a user uploads such information about him-/herself or others, the user is responsible for ensuring a legal ground for disclosing such information.</p>
<h1>4. Transfer to third parties</h1>
<p>In the context of the fulfilment of the purposes set out above, Fost Plus calls upon third party service providers, e.g. IT service providers. If necessary, to carry out their task, your personal data may also be transferred to these third-party service providers. In that case, they process your personal data solely in accordance with the instructions of Fost Plus and in line with the applicable regulations.</p>
<p>Fost Plus may also transfer certain personal data to external parties for processing for their own purposes while using their own means. In that case, these third parties act as the data controller under the applicable data protection legislation. This includes the transfer to competent authorities in the context of urgent or pending legal proceedings, in the context of judicial investigations and requests of information, at the request of the government or to comply with other legal obligations.</p>
<h1>5. Transfer to countries outside of the EEA</h1>
<p>We currently do not transfer your data to parties in countries outside the European Economic Area (EEA). If this would change in the future, we will ensure that your personal data is only transferred to parties that apply an appropriate level of data protection (e.g. by including the so-called EU standard contractual clauses of the European Union in the agreement with the recipient subject to an appropriate risk assessment).</p>
<h1>6. Your rights as a data subject</h1>
<p>As a data subject, you have the following rights:</p>
<p><strong>Right to access</strong> &ndash; You have the right to consult your personal data that we process, as well as the right to obtain a copy of these personal data (barring certain exceptions).</p>
<p><strong>Right to data portability</strong> &ndash; Under certain conditions, you have the right to receive the personal data you provided, in a structured, commonly used and machine-readable format, so you can transmit those data to another party.</p>
<p><strong>Right to rectification and erasure</strong> &ndash; You have, at all times, the possibility of having your data corrected or deleted by us free of charge, provided the legal conditions are met. Data that we need for the establishment, exercise or defence of legal claims or which we are legally obliged to keep, are exempt from the right to erasure.</p>
<p><strong>Restriction of the processing</strong> &ndash; Provided that the valid legal conditions are fulfilled, you can demand that we restrict the processing of your personal data.</p>
<p><strong>Right to object </strong>&ndash; You may object, at any time, to processing of your personal data for direct marketing purposes or to processing that relies on our legitimate interest as the legal ground. When objecting to the processing of personal data for direct marketing purposes, you do not need to provide us with a reason.</p>
<p><strong>Withdrawal of your consent</strong> &ndash; If we process your personal data on the basis of your consent, you have the right to withdraw this consent at any time. However, this withdrawal will not influence the legality of the processing of your personal data for the period prior to the moment of this withdrawal and processing activities that are based on another legal ground.</p>
<p><strong>Complaint to the competent authority</strong> &ndash; You have the right at all times to contact the data protection authority in Belgium and, if appropriate, lodge a complaint.</p>
<p>To exercise the above rights, send an e-mail to privacy@fostplus.be. This e-mail must be accompanied by your full contact details and a means of verification of your identity (e.g. a copy of the front of your identity card or driving licence). Fost Plus may, however, refuse requests which it considers excessive or to be an abuse of the relevant law.</p>
<h1>7. Storing your data</h1>
<p>In principle, Fost Plus keeps your personal data only for as long as this is necessary for the purposes described above (bearing in mind the applicable legal prescription periods). For instance, personal data contained in questions that you ask us, are in principle only kept for a period of two years after receipt of the question. However, the data that we process &ndash; to the extent that you wish so &ndash; to keep you informed of our initiatives, etc. are kept up to date and processed for as long as you do not object to receiving newsletters, (digital) marketing or other information. Personal data that are linked to contractual documents (e.g. identification or transaction data) are kept for as long as your account on the Click is active and for a maximum period of ten years after the termination of the contract with Fost Plus, barring legal exceptions. After these periods, the data will be deleted.</p>
<p>Your account will remain active, until you request its deactivation or deletion.</p>
<h1>8. Modifications</h1>
<p>Fost Plus reserves the right to modify the Privacy Statement at any time, among other things to adapt it to changes in the services provided or amendments to the legal and/or regulatory requirements. Modifications to this Privacy Statement come into force at the time of publication. We advise you to always consult the most recent version. This Privacy Statement was published on July 25<sup>th</sup>,2020 and last updated on June 25<sup>th</sup>, 2021.</p>
<h1>9. Contact details of data protection manager and authorities</h1>
<p>For questions about the processing of your personal data or exercising of your rights as a data subject, you can contact our Data Protection Manager (DPM) at any time. Our DPM was appointed, among other things, specifically to act as the contact person for persons whose data we process.</p>
<p>You can reach our DPM by e-mail (privacy@fostplus.be), by telephone on +32 2 775 03 50 or by sending a letter to: Fost Plus Data Protection Manager, Olympiadenlaan 2 Avenue des Olympiades, 1140 Brussels, Belgium.</p>
<p>You can also contact the Belgian Data Protection authority with any questions or complaints (https://www.dataprotectionauthority.be/).</p>
 </div>`,
  terms_of_use_html: `<div>
   <p>
    Terms of Use – the Click by Fost Plus
</p>
<h1>
    1. General Information
</h1>
<p>
    These are the terms of use of sorscan.be mobile app and website we provide
    (“<strong>App”</strong>). The App is operated by or on behalf of Fost Plus
    vzw/asbl (<strong>we, us </strong>and<strong> our</strong>). We are a
    non-profit organisation, registered in Belgium, with company number
    0447.550.872, and our registered office is at Olympiadenlaan 2 Avenue des
    Olympiades, 1140 Brussels, Belgium.
</p>
<p>
    We remain the owner of the App at all times and we license use of the App
    and the services offered via the App to you on the basis of these terms of
    use ("<strong>Terms</strong>"). By indicating on the registration screen of
    the App that you agree to these Terms, you acknowledge to have read and to
    be bound by these Terms and you agree only to use the App in accordance
    with these Terms. If you do not agree with the content of the Terms, please
    do not continue to use the App.
</p>
<p>
    Use of your personal information submitted to or via the App is governed by
    our Privacy Statement, which explains how we use your personal data.
</p>
<p>
    Your use of the App may also be subject to the terms or policies applied by
    any third-party provider or operator from whose site or platform you
    downloaded the App (e.g. browser provider, appstore). We encourage you to
    read and adhere to those terms and policies.
</p>
<p>
These Terms were last updated on    <strong><em>June 25<sup>th</sup>, 2021</em></strong>. The latest version of
    these Terms is always available via the App. It is your responsibility to
    review the applicable Terms on a regular basis and to comply with the
    latest version.
</p>
<h1>
    2. Purpose and content of the App
</h1>
<p>
    For the purpose of these Terms, we understand litter to include: packaging
    or waste of out of home used items and goods (e.g. on festivals or similar
    events) or packaging thrown away in public areas. Household waste is
    excluded, should not be uploaded and will not count.
</p>
<p>
    We have created the App in order to stimulate circularity and instigate
    people to pick up litter and put it in the right waste bin. By scanning
    barcodes or taking a picture of pieces of litter you can obtain sorting
    instructions on how to dispose them.
</p>
<p>
    By scanning or taking pictures of more items you will increase our database
    but also the chances of receiving a correct sorting advice.
</p>
<h1>
    3. Accounts
</h1>
<p>
    You should only use this App if you agree to access and use the App in
    accordance with these Terms.
</p>
<p>
    <strong>
        If you are under the age of 16, you need to obtain permission from an
        adult with effective parental responsibility over you before accessing
        and using the App.
    </strong>
</p>
<p>
    To use the App, you must register and set up your account with us by
    completing the account registration form in the App. You will need to
    provide your own name and email address. You only need to register once.
    You must provide us with true, accurate, complete and up-to-date
    information. You are responsible for the information you provide to us.
</p>
<p>
    Upon registration for an account with us, you will be asked to create a
    password. You must keep your password confidential at all times and use it
    only to access and use your account and not for any other purpose. You are
    the only authorised user of your account and, accordingly, you must not
    disclose your password to anyone else, nor shall you use the e-mail address
    or password of someone else. You understand and agree that we cannot be
    held liable for the consequences of the unlawful and unauthorised use of
    the App through the use of your username and password. You should contact
    us immediately upon discovering any unauthorised use of your account or
    error in the operation of your password (see section Communication below
    for further details). You are responsible for all actions that occur
    through your account. Any breach of these Terms and/or any use of your
    account by anyone to whom you disclose your password will be treated as if
    the breach or use had been carried out by you and will not relieve you of
    your obligations to us.
</p>
<h1>
    4. Use of the App
</h1>
<h2>
    4.1.Your use
</h2>
<p>
    The use of the App is free. You just need an internet connection.
</p>
<p>
    You may only use the App for non-commercial use (unless expressly granted
    permission, in writing, to do otherwise by us) and only in accordance with
    these Terms. You agree that your use of the App is on an ‘as is’ and ‘as
    available’ basis and at your sole risk.
</p>
<p>
    You understand that in order to use the App properly, you need to authorize
    the App to access:
</p>
<p>
    - <u>Your camera</u>: in order for you to be able to take pictures and scan
    barcodes and receive Circular UCoins. The App will only access the camera
    when you use the App.
</p>
<p>
    If you do not authorize the App to access your location and camera, you
    will not be able to enjoy the full functionality of the App and, in
    particular, not be able to earn Circular UCoins.
</p>
<p>
    Except to the extent expressly set out in these Terms, you are not allowed
    to:
</p>
<p>
    - ‘scrape’ content or store content of the App on a server or other storage
    device connected to a network or create an electronic database by
    systematically downloading and storing all of the content of the App, nor
    are you allowed to “mirror” the App on another server or application,
    without our prior written consent;
</p>
<p>
    - submit any content to the App which is false, misleading or fraudulent or
    which could be considered defamatory, discriminatory, abusive, offensive or
    otherwise objectionable, or infringes upon the rights of third parties;
</p>
<p>
    - remove or change any content of the App, or attempt to circumvent
    protection, security or confidentiality measures, or interfere with the
    proper working of the App or the servers on which the App is hosted;
</p>
<p>
    - republish, redistribute, reverse-engineer, exploit or re-transmit the
    App, and/or any of the content made available on the App; or
</p>
<p>
    - otherwise do anything that is not expressly permitted by these Terms.
</p>
<p>
    You must only use the App for lawful purposes (complying with all
    applicable laws and regulations), in a responsible manner, and not in a way
    that might damage our name or reputation or that of any of our employees,
    agents or representatives.
</p>
<p>
    We are giving you personally the right to use the App as set out in these
    Terms. You may not transfer your rights or obligations under these Terms to
    another person without our prior written agreement. You agree that if you
    sell any device on which the App is installed, you must first remove the
    App from it.
</p>
<h2>
    4.2. Suspension of operation of the App
</h2>
<p>
    We cannot guarantee the continuous, uninterrupted or error-free operability
    of the App. We may, from time to time, in our sole discretion and with or
    without prior notice, temporarily suspend the operation of certain
    features, parts or content of the App (in whole or in part) for repair or
    maintenance work or in order to withdraw, modify, update or upgrade any
    contents, features or functionality.
</p>
<p>
    You agree that we will not be liable to you or to any third-party, subject
    to the liability terms set out in the section Warranties and liability, for
    any unavailability, modification, suspension or withdrawal of the App, or
    any features, parts or content of the App.
</p>
<p>
    The App connects to services via the internet provided by your third-party
    internet services provider or mobile services provider's network and a
    stable internet connection is required to access our services. You
    acknowledge and agree that we are not responsible for any unavailability of
    the internet, connection quality issues, service interruption or diminished
    functionality in the App which you may experience as a result of any such
    unavailability of any part of the internet or issue with your device's
    internet connectivity.
</p>
<p>
    .
</p>
<h2>
    4.3.Termination of our relationship
</h2>
<p>
    We may, with or without prior notice, suspend or terminate the contractual
    relationship between you and us or your use of your account in the event
    that you have breached any of these Terms.
</p>
<p>
    If you have breached these Terms, we may take such action as we deem
    appropriate. Such a breach by you may result in our taking, with or without
    notice, all or any of the following actions:
</p>
<p>
    - issue of a warning to you;
</p>
<p>
    - immediate, temporary or permanent removal of any content submitted by
    you;
</p>
<p>
    - immediate, temporary or permanent withdrawal of your right to access and
    use the App;
</p>
<p>
    - legal proceedings against you for reimbursement of all recoverable loss
    and damage resulting from the breach; and/or
</p>
<p>
    - disclosure of all relevant information to law enforcement authorities as
    we reasonably feel is necessary.
</p>
<p>
    The rights described above are cumulative and are not limited, and we may
    take any other action we deem appropriate.
</p>
<p>
    Upon termination of these Terms or your account, for any reason, with or
    without court intervention:
</p>
<p>
    - all rights granted to you under these Terms will immediately cease;
</p>
<p>
    - you must promptly discontinue all use of the App and delete or remove the
    App from all (mobile) devices in your possession.
</p>
<p>
    You can terminate the contractual relationship by asking us to deactivate
    your account.
</p>
<h1>
    5. Warranties and liability
</h1>
<p>
    Whilst we take reasonable steps to ensure that the App is free of malware
    and viruses, we cannot and do not guarantee that the App will be entirely
    free from viruses and/or other code that may have contaminating or
    destructive elements. It is your responsibility to implement appropriate IT
    security safeguards (including: installing updates of the App, performing
    anti-virus and other security checks) to satisfy your particular
    requirements as to the safety and reliability of content you access through
    our App.
</p>
<p>
    You understand and agree that, except for the cases in which the law does
    not allow liability to be excluded, we cannot and under no circumstances
    (regardless of the gravity of the fault) be held liable in any direct or
    indirect, general or specific way for any direct or indirect damage,
    including, among others, damage resulting from loss of profit, loss of
    data, commercial loss, business interruption, loss of business opportunity,
    or any other commercial damage or loss resulting from (i) your use of the
    App or inability to use the App, (ii) any changes made to the App or the
    discontinuation of the App, (iii) the deletion, damage or failure of
    storage of content and other data held or transmitted through the use of
    the App, (iv) non-compliance by third party vendors with their obligations
    in the context of a transaction between you and such third party vendor or
    (iv) force majeure.
</p>
<p>
    You agree to fully indemnify and hold us harmless from and against any
    third party claims, arising from or related to the violation of these Terms
    .
</p>
<p>
    No agency, partnership, joint venture, employment relationship or franchise
    relationship is intended or created between you and us as a result of your
    use of the App.
</p>
<h1>
    6. Intellectual property rights
</h1>
<p>
    You acknowledge that all intellectual property rights in any content of the
    App (including text, graphics, software, photographs and other images,
    videos, sound, trademarks and logos) are owned by us or our licensors.
</p>
<p>
    Except as expressly set out here or as expressly allowed to you in writing
    by us, nothing in these Terms gives you any rights in respect of any
    intellectual property owned by us or our licensors and you acknowledge that
    you do not acquire any ownership or usage rights by downloading content
    from the App. In the event you print off, print screen, copy or store pages
    from the App, you must ensure that any copyright, trademark or other
    intellectual property right notices contained in the original content, are
    reproduced.
</p>
<h1><p>
    Terms of Use – the Click by Fost Plus
</p>
<h1>
    1. General Information
</h1>
<p>
    These are the terms of use of sorscan.be mobile app and website we provide
    (“<strong>App”</strong>). The App is operated by or on behalf of Fost Plus
    vzw/asbl (<strong>we, us </strong>and<strong> our</strong>). We are a
    non-profit organisation, registered in Belgium, with company number
    0447.550.872, and our registered office is at Olympiadenlaan 2 Avenue des
    Olympiades, 1140 Brussels, Belgium.
</p>
<p>
    We remain the owner of the App at all times and we license use of the App
    and the services offered via the App to you on the basis of these terms of
    use ("<strong>Terms</strong>"). By indicating on the registration screen of
    the App that you agree to these Terms, you acknowledge to have read and to
    be bound by these Terms and you agree only to use the App in accordance
    with these Terms. If you do not agree with the content of the Terms, please
    do not continue to use the App.
</p>
<p>
    Use of your personal information submitted to or via the App is governed by
    our Privacy Statement, which explains how we use your personal data.
</p>
<p>
    Your use of the App may also be subject to the terms or policies applied by
    any third-party provider or operator from whose site or platform you
    downloaded the App (e.g. browser provider, appstore). We encourage you to
    read and adhere to those terms and policies.
</p>
<p>
These Terms were last updated on    <strong><em>June 25<sup>th</sup>, 2021</em></strong>. The latest version of
    these Terms is always available via the App. It is your responsibility to
    review the applicable Terms on a regular basis and to comply with the
    latest version.
</p>
<h1>
    2. Purpose and content of the App
</h1>
<p>
    For the purpose of these Terms, we understand litter to include: packaging
    or waste of out of home used items and goods (e.g. on festivals or similar
    events) or packaging thrown away in public areas. Household waste is
    excluded, should not be uploaded and will not count.
</p>
<p>
    We have created the App in order to stimulate circularity and instigate
    people to pick up litter and put it in the right waste bin. By scanning
    barcodes or taking a picture of pieces of litter you can obtain sorting
    instructions on how to dispose them.
</p>
<p>
    By scanning or taking pictures of more items you will increase our database
    but also the chances of receiving a correct sorting advice.
</p>
<h1>
    3. Accounts
</h1>
<p>
    You should only use this App if you agree to access and use the App in
    accordance with these Terms.
</p>
<p>
    <strong>
        If you are under the age of 16, you need to obtain permission from an
        adult with effective parental responsibility over you before accessing
        and using the App.
    </strong>
</p>
<p>
    To use the App, you must register and set up your account with us by
    completing the account registration form in the App. You will need to
    provide your own name and email address. You only need to register once.
    You must provide us with true, accurate, complete and up-to-date
    information. You are responsible for the information you provide to us.
</p>
<p>
    Upon registration for an account with us, you will be asked to create a
    password. You must keep your password confidential at all times and use it
    only to access and use your account and not for any other purpose. You are
    the only authorised user of your account and, accordingly, you must not
    disclose your password to anyone else, nor shall you use the e-mail address
    or password of someone else. You understand and agree that we cannot be
    held liable for the consequences of the unlawful and unauthorised use of
    the App through the use of your username and password. You should contact
    us immediately upon discovering any unauthorised use of your account or
    error in the operation of your password (see section Communication below
    for further details). You are responsible for all actions that occur
    through your account. Any breach of these Terms and/or any use of your
    account by anyone to whom you disclose your password will be treated as if
    the breach or use had been carried out by you and will not relieve you of
    your obligations to us.
</p>
<h1>
    4. Use of the App
</h1>
<h2>
    4.1.Your use
</h2>
<p>
    The use of the App is free. You just need an internet connection.
</p>
<p>
    You may only use the App for non-commercial use (unless expressly granted
    permission, in writing, to do otherwise by us) and only in accordance with
    these Terms. You agree that your use of the App is on an ‘as is’ and ‘as
    available’ basis and at your sole risk.
</p>
<p>
    You understand that in order to use the App properly, you need to authorize
    the App to access:
</p>
<p>
    - <u>Your camera</u>: in order for you to be able to take pictures and scan
    barcodes and receive Circular UCoins. The App will only access the camera
    when you use the App.
</p>
<p>
    If you do not authorize the App to access your location and camera, you
    will not be able to enjoy the full functionality of the App and, in
    particular, not be able to earn Circular UCoins.
</p>
<p>
    Except to the extent expressly set out in these Terms, you are not allowed
    to:
</p>
<p>
    - ‘scrape’ content or store content of the App on a server or other storage
    device connected to a network or create an electronic database by
    systematically downloading and storing all of the content of the App, nor
    are you allowed to “mirror” the App on another server or application,
    without our prior written consent;
</p>
<p>
    - submit any content to the App which is false, misleading or fraudulent or
    which could be considered defamatory, discriminatory, abusive, offensive or
    otherwise objectionable, or infringes upon the rights of third parties;
</p>
<p>
    - remove or change any content of the App, or attempt to circumvent
    protection, security or confidentiality measures, or interfere with the
    proper working of the App or the servers on which the App is hosted;
</p>
<p>
    - republish, redistribute, reverse-engineer, exploit or re-transmit the
    App, and/or any of the content made available on the App; or
</p>
<p>
    - otherwise do anything that is not expressly permitted by these Terms.
</p>
<p>
    You must only use the App for lawful purposes (complying with all
    applicable laws and regulations), in a responsible manner, and not in a way
    that might damage our name or reputation or that of any of our employees,
    agents or representatives.
</p>
<p>
    We are giving you personally the right to use the App as set out in these
    Terms. You may not transfer your rights or obligations under these Terms to
    another person without our prior written agreement. You agree that if you
    sell any device on which the App is installed, you must first remove the
    App from it.
</p>
<h2>
    4.2. Suspension of operation of the App
</h2>
<p>
    We cannot guarantee the continuous, uninterrupted or error-free operability
    of the App. We may, from time to time, in our sole discretion and with or
    without prior notice, temporarily suspend the operation of certain
    features, parts or content of the App (in whole or in part) for repair or
    maintenance work or in order to withdraw, modify, update or upgrade any
    contents, features or functionality.
</p>
<p>
    You agree that we will not be liable to you or to any third-party, subject
    to the liability terms set out in the section Warranties and liability, for
    any unavailability, modification, suspension or withdrawal of the App, or
    any features, parts or content of the App.
</p>
<p>
    The App connects to services via the internet provided by your third-party
    internet services provider or mobile services provider's network and a
    stable internet connection is required to access our services. You
    acknowledge and agree that we are not responsible for any unavailability of
    the internet, connection quality issues, service interruption or diminished
    functionality in the App which you may experience as a result of any such
    unavailability of any part of the internet or issue with your device's
    internet connectivity.
</p>
<p>
    .
</p>
<h2>
    4.3.Termination of our relationship
</h2>
<p>
    We may, with or without prior notice, suspend or terminate the contractual
    relationship between you and us or your use of your account in the event
    that you have breached any of these Terms.
</p>
<p>
    If you have breached these Terms, we may take such action as we deem
    appropriate. Such a breach by you may result in our taking, with or without
    notice, all or any of the following actions:
</p>
<p>
    - issue of a warning to you;
</p>
<p>
    - immediate, temporary or permanent removal of any content submitted by
    you;
</p>
<p>
    - immediate, temporary or permanent withdrawal of your right to access and
    use the App;
</p>
<p>
    - legal proceedings against you for reimbursement of all recoverable loss
    and damage resulting from the breach; and/or
</p>
<p>
    - disclosure of all relevant information to law enforcement authorities as
    we reasonably feel is necessary.
</p>
<p>
    The rights described above are cumulative and are not limited, and we may
    take any other action we deem appropriate.
</p>
<p>
    Upon termination of these Terms or your account, for any reason, with or
    without court intervention:
</p>
<p>
    - all rights granted to you under these Terms will immediately cease;
</p>
<p>
    - you must promptly discontinue all use of the App and delete or remove the
    App from all (mobile) devices in your possession.
</p>
<p>
    You can terminate the contractual relationship by asking us to deactivate
    your account.
</p>
<h1>
    5. Warranties and liability
</h1>
<p>
    Whilst we take reasonable steps to ensure that the App is free of malware
    and viruses, we cannot and do not guarantee that the App will be entirely
    free from viruses and/or other code that may have contaminating or
    destructive elements. It is your responsibility to implement appropriate IT
    security safeguards (including: installing updates of the App, performing
    anti-virus and other security checks) to satisfy your particular
    requirements as to the safety and reliability of content you access through
    our App.
</p>
<p>
    You understand and agree that, except for the cases in which the law does
    not allow liability to be excluded, we cannot and under no circumstances
    (regardless of the gravity of the fault) be held liable in any direct or
    indirect, general or specific way for any direct or indirect damage,
    including, among others, damage resulting from loss of profit, loss of
    data, commercial loss, business interruption, loss of business opportunity,
    or any other commercial damage or loss resulting from (i) your use of the
    App or inability to use the App, (ii) any changes made to the App or the
    discontinuation of the App, (iii) the deletion, damage or failure of
    storage of content and other data held or transmitted through the use of
    the App, (iv) non-compliance by third party vendors with their obligations
    in the context of a transaction between you and such third party vendor or
    (iv) force majeure.
</p>
<p>
    You agree to fully indemnify and hold us harmless from and against any
    third party claims, arising from or related to the violation of these Terms
    .
</p>
<p>
    No agency, partnership, joint venture, employment relationship or franchise
    relationship is intended or created between you and us as a result of your
    use of the App.
</p>
<h1>
    6. Intellectual property rights
</h1>
<p>
    You acknowledge that all intellectual property rights in any content of the
    App (including text, graphics, software, photographs and other images,
    videos, sound, trademarks and logos) are owned by us or our licensors.
</p>
<p>
    Except as expressly set out here or as expressly allowed to you in writing
    by us, nothing in these Terms gives you any rights in respect of any
    intellectual property owned by us or our licensors and you acknowledge that
    you do not acquire any ownership or usage rights by downloading content
    from the App. In the event you print off, print screen, copy or store pages
    from the App, you must ensure that any copyright, trademark or other
    intellectual property right notices contained in the original content, are
    reproduced.
</p>
<h1> 7. Communication </h1>
<p>
    The user is responsible for checking that the information/photos or that
    he makes available via the app/website is free from any intellectual
    property right and/or grants an unlimited perpetual license to use the
photo/information, with regard to third parties or the general public.    
</p>
<p>
    All notices given by you to us must be given in writing to the postal or
    e-mail address set out below. We may give notice to you at the e-mail
    address you provide to us.
</p>
<p>
    Please submit any questions you have about these Terms or the App, any
    complaint or concern in relation to the App or report an issue with the App
by email to info@sortscan.be or write to us at: <em>Fost Plus, </em>    <em>Olympiadenlaan 2 Avenue des Olympiades, 1140 Brussels, Belgium</em>
</p>
<h1>
    8. Modifications
</h1>
<p>
    As it is our policy to continuously review and update the App, we may
    revise these Terms from time to time and will notify you if we are making
    any significant changes.
</p>
<h1>
    9. Applicable law and competent courts
</h1>
<p>
    These Terms shall be governed by and interpreted in accordance with Belgian
    law.
</p>
<p>
    In the event of a dispute, only the courts of Belgium have jurisdiction.
</p>

    7. The user is responsible for checking that the information/photos or that
    he makes available via the app/website is free from any intellectual
    property right and/or grants an unlimited perpetual license to use the
photo/information, with regard to third parties or the general public.    Communication</a>
</h1>
<p>
    All notices given by you to us must be given in writing to the postal or
    e-mail address set out below. We may give notice to you at the e-mail
    address you provide to us.
</p>
<p>
    Please submit any questions you have about these Terms or the App, any
    complaint or concern in relation to the App or report an issue with the App
by email to info@sortscan.be or write to us at: <em>Fost Plus, </em>    <em>Olympiadenlaan 2 Avenue des Olympiades, 1140 Brussels, Belgium</em>
</p>
<h1>
    8. Modifications
</h1>
<p>
    As it is our policy to continuously review and update the App, we may
    revise these Terms from time to time and will notify you if we are making
    any significant changes.
</p>
<h1>
    9. Applicable law and competent courts
</h1>
<p>
    These Terms shall be governed by and interpreted in accordance with Belgian
    law.
</p>
<p>
    In the event of a dispute, only the courts of Belgium have jurisdiction.
</p>


 </div>`,
  ranking_playing_as_text: "Playing as",
  ranking_select_text: "Choose your username",
  ranking_submit_button_text: "Select",
  ranking_input_field_label: "Your username",
  ranking_input_field_validate_succes: "Looks good",
  ranking_input_field_validate_error:
    "Username should be between 3 and 12 characters",

  ranking_choose_new_username_text: "Choose new",
  ranking_table_header_ranking: "Ranking",
  ranking_table_header_username: "Username",
  ranking_table_header_points: "Points",
  username_dialog_title: "Fill in your username to score points",
  username_dialog_submit_text: "Play",
  username_dialog_skip_text: "Skip",
  footer_powered_by: "SortScan",
  api_error_random_image: "Failed to retrieve a random image",
  api_error_upload_image: "Failed to upload the selected image",
  api_error_processing_image: "Selected image was not processed as it might contain obscene/personally identifiable content.",
  api_error_report_mistake: "Failed to report the mistake",
  api_error_validate_guess: "Failed to submit your guess",
};