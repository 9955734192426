import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import Dialog from "../components/Dialog";
import { create_user } from "../api/api";
import { GameContext } from "../contexts/GameContext";
import UsernameSelection from "../atomic/UsernameSelection";
import { validateUsername } from "../validation/username";
import Button from "../atomic/Button";
import { LanguageContext } from "../contexts/LanguageContext";

const UserSelection = () => {
  const navigate = useNavigate();
  const { syncRanking, setUser } = useContext(GameContext);
  const { texts } = useContext(LanguageContext);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(undefined);

  const playAsUser = () => {
    create_user(username).then(() => {
      setUser({ username });
      syncRanking();
      navigate("/play");
    });
  };

  const validateAndUpdateUsername = (username) => {
    setUsername(username);
    setUsernameError(validateUsername(username).error);
  };

  const playAnonymous = () => {
    navigate("/play");
  };

  return (
    <Dialog className="max-w-screen-sm">
      <div className="drop-shadow-lg rounded-lg p-8 bg-white flex flex-col gap-8">
        <div className="font-black text-2xl">{texts.username_dialog_title}</div>
        <UsernameSelection
          username={username}
          setUsername={validateAndUpdateUsername}
          error={usernameError}
        />

        <div className="flex gap-4">
          <div className="flex-grow">
            <Button
              className="bg-[#d8d8d8] text-slate-500 border-[#cbcbcb] w-full"
              onClick={playAnonymous}
            >
              {texts.username_dialog_skip_text}
            </Button>
          </div>
          <div className="self-center text-gray-300 text-sm">OR</div>
          <div className="flex-grow self-center">
            <Button
              onClick={playAsUser}
              className="w-full"
              disabled={username.length === 0 || usernameError}
            >
              {texts.username_dialog_submit_text}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UserSelection;
