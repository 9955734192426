import React from "react";
import FractionPill from "./FractionPill";
import { FractionColors } from "../config/FractionColors";
import classnames from "classnames";

const FRACTION_SEC_COLORS = {
  PMD: "#315D75",
  PAPER: "#76612E",
  GLASS: "#03453E",
  GFT: "#47362D",
  KGA: "#590D1B",
  REST: "#4A4A4A",
  NO_WASTE: "#00A300",
  OTHER: "#DEC146"
};

const FractionButton = ({ name, fraction_key, onClick, selected }) => {
  const backgroundColor = !selected ? "#e5e7eb" : FractionColors[fraction_key];
  const textColor = !selected ? "#374151" : FRACTION_SEC_COLORS[fraction_key];

  return (
    <div
      className={classnames(
        "rounded-md flex max-h-16 cursor-pointer text-gray-700 p-3 bg-zinc-100",
        !selected && "shadow-inner"
      )}
      key={fraction_key}
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
      }}
    >
      <div
        className="self-center text-xl font-bold"
        style={{
          display: "inherit",
          alignItems: "center",
          width: "100%",
        }}
      >
        <FractionPill
          name={fraction_key}
          className="self-center  align-middle"
        />
        <span
          className="pl-2 align-middle"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {name}
        </span>
      </div>
    </div>
  );
};

export default FractionButton;
