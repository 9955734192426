import React, { useContext, useState } from "react";
import WrongAnswerSVG from "../images/wrong_answer.svg";
import ImagePicker from "../components/ImagePicker";
import RandomImagePicker from "../components/RandomImagePicker";
import { GameContext } from "../contexts/GameContext";
import { LanguageContext } from "../contexts/LanguageContext";
import FractionPill from "../components/FractionPill";
import { reportMistake } from "../api/api";
import LoadingSpinner from "./LoadingSpinner";
import { useNavigate } from "react-router";
import PredictedFractionText from "./PredictedFractionText";
import toast from "react-hot-toast";

const GameFlowWrongAnswer = () => {
  const { result, image, user, syncRanking } = useContext(GameContext);
  const languageContext = useContext(LanguageContext);
  const navigate = useNavigate();
  const [reportedMistake, setReportedMistake] = useState(false);

  const ReportMistakeButton = () => {
    const [loading, setLoading] = useState(false);

    const handleReport = () => {
      setLoading(true);
      reportMistake(image?.image_id, result?.guessed_fraction, user?.username)
        .then(() => {
          setReportedMistake(true);
          syncRanking();
          setLoading(false);
        })
        .catch(() => {
          toast.error(languageContext.texts.api_error_report_mistake);
          setLoading(false);
        });
    };

    return (
      <button className="btn-error self-end mb-4" onClick={handleReport}>
        {loading && <LoadingSpinner />}
        <span>Report mistake</span>
      </button>
    );
  };

  return (
    <>
      <div className="GameFlow_Result drop-shadow-lg rounded-lg p-8  bg-white flex flex-col text-center">
        {!reportedMistake ? (
          <ReportMistakeButton />
        ) : (
          <div className="self-end">
            <div
              id="toast-success"
              className="flex items-center w-full max-w-xs p-4 mb-4 text-zinc-600 bg-zinc-100 rounded-lg shadow"
              role="alert"
            >
              <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <div className="ml-3 text-sm font-normal">
                {languageContext.texts.report_success_text}
              </div>
            </div>
          </div>
        )}
        <img
          src={WrongAnswerSVG}
          alt="Wrong answer emoji"
          className="max-w-md w-52 self-center "
        />
        <h1 className="text-center font-black text-3xl  text-slate-800 py-4 max-w-lg mx-auto">
          {languageContext.texts.wrong_answer_title}
        </h1>

        <div className="GameFlow_Result_Desc max-w-sm self-center">
          <div>{languageContext.texts.wrong_answer_text_before_fraction}</div>
          <div>
            <span>
              {result?.predicted_fraction && (
                <PredictedFractionText name={result?.predicted_fraction} />
              )}
            </span>
            <FractionPill name={result?.predicted_fraction} />
          </div>
          <div>{languageContext.texts.wrong_answer_text_after_fraction}</div>
        </div>
      </div>
      <div className="GameFlow_Result_Actions  grid grid-cols-2 gap-4 ">
        <ImagePicker
          className="btn-primary text-center"
          routeToPlay={() => navigate("/play")}
        >
          <span>{languageContext.texts.upload_button_text}</span>
        </ImagePicker>
        <RandomImagePicker
          className="btn-primary"
          routeToPlay={() => navigate("/play")}
        >
          <span>{languageContext.texts.random_button_text}</span>
        </RandomImagePicker>
      </div>
    </>
  );
};

export default GameFlowWrongAnswer;
