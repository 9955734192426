import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import { NL } from "../config/NL";
import { FR } from "../config/FR";
import { EN } from "../config/EN";
import classNames from "classnames";

const LanguageSelector = () => {
  const { texts, setTexts } = useContext(LanguageContext);

  const handleClick = (selection) => {
    if (
      location.hostname === "localhost" ||
      location.hostname === "127.0.0.1"
    ) {
      setTexts(selection);
    } else {
      let baseUrl = "https://sortscan.be";

      if (selection.key === NL.key) {
        baseUrl = "https://sorteerscan.be";
      } else if (selection.key === FR.key) {
        baseUrl = "https://triscan.be";
      }
      window.location.href = baseUrl + window.location.pathname;
    }
  };

  return (
    <div className="md:block font-medium text-[#7fba5b]">
      <span
        className={classNames(
          "hover:text-white",
          texts.key === NL.key ? "text-bolder text-white" : ""
        )}
        onClick={() => handleClick(NL)}
      >
        NL
      </span>
      {" | "}
      <span
        className={classNames(
          "hover:text-white",
          texts.key === FR.key ? "text-bolder text-white" : ""
        )}
        onClick={() => handleClick(FR)}
      >
        FR
      </span>
      {" | "}
      <span
        className={classNames(
          "hover:text-white",
          texts.key === EN.key ? "text-bolder text-white" : ""
        )}
        onClick={() => handleClick(EN)}
      >
        EN
      </span>
    </div>
  );
};

export default LanguageSelector;
