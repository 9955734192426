import React, { useContext, useState } from "react";
import { GameContext } from "../contexts/GameContext";
import { createImage } from "../api/api";
import LoadingSpinner from "../atomic/LoadingSpinner";
import toast from "react-hot-toast";
import { LanguageContext } from "../contexts/LanguageContext";

const ImagePicker = ({ children, className, routeToPlay }) => {
  const { setImage, image, user, syncRanking } = useContext(GameContext);
  const { texts } = useContext(LanguageContext);
  const [loading, setIsLoading] = useState(false);

  const onFileChange = (event) => {
    setIsLoading(true);
    createImage(event.target.files[0], user?.username)
      .then((result) => {
        setImage({ ...image, ...result.data });
        setIsLoading(false);
        routeToPlay();
        syncRanking();
      })
      .catch((error) => {
        if (error.response.status == 422) {
          toast.error(texts.api_error_processing_image);
        } else {
          toast.error(texts.api_error_upload_image);
        }
        setIsLoading(false);
      });
  };

  return (
    <label className="cursor-pointer">
      <input
        style={{ display: "none" }}
        type="file"
        accept="image/*"
        capture="environment"
        onChange={onFileChange}
      />
      <div className={className}>
        {loading && <LoadingSpinner />}
        {children}
      </div>
    </label>
  );
};

export default ImagePicker;
