import React, { useContext, useEffect } from "react";
import { GameContext } from "../contexts/GameContext";
import { useNavigate } from "react-router";
import GameFlowGoodAnswer from "../atomic/GoodAnswerView";
import GameFlowWrongAnswer from "../atomic/WrongAnswerView";
import Dialog from "../components/Dialog";

const GameUserResult = () => {
  const { image, result } = useContext(GameContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (image === undefined) {
      navigate("/");
    }
  }, []);

  return (
    <Dialog>
      {result && result.result == "CORRECT" ? (
        <GameFlowGoodAnswer />
      ) : (
        <GameFlowWrongAnswer />
      )}
    </Dialog>
  );
};

export default GameUserResult;
