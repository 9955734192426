import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Toaster } from "react-hot-toast";

const Container = ({ children }) => {
  return (
    <div className="App bg-slate-100 min-h-screen flex flex-col ">
      <Toaster reverseOrder={false} />

      <Header />
      <div className="flex-grow p-2 md:p-0">{children}</div>

      <Footer />
    </div>
  );
};

export default Container;
