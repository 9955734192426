import React, { useState, useContext } from "react";
import PredictedFractionText from "../atomic/PredictedFractionText";
import { LanguageContext } from "../contexts/LanguageContext";
import FractionButton from "./FractionButton";

const ImageFeedback = ({ image, submitFeedback, loading }) => {
  const [selectedFraction, setSelectedFraction] = useState(undefined);
  const { texts } = useContext(LanguageContext);

  const FRACTIONS = [
    { key: "PMD" },
    { key: "PAPER" },
    { key: "GLASS" },
    { key: "GFT" },
    { key: "KGA" },
    { key: "REST" },
    { key: "OTHER"},
    { key: "NO_WASTE"},
  ];

  const FractionForm = () => {
    return (
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
        {FRACTIONS.map((fraction) => {
          return (
            <FractionButton
              key={fraction.key}
              fraction_key={fraction.key}
              name={<PredictedFractionText name={fraction.key} />}
              selected={selectedFraction == fraction.key}
              onClick={() => {
                if (!selectedFraction) {
                  submitFeedback(fraction.key);
                  setSelectedFraction(fraction.key);
                }
              }}
            />
          );
        })}
      </div>
    );
  };
  return (
    <div className="flex flex-col gap-4 ">
      <div className="flex-shrink self-center">
        {image && (
          <img
            className="shadow-sm  rounded-lg max-h-80"
            src={`data:image/png;base64,${image}`}
          />
        )}
      </div>
      <div>
        <FractionForm />
      </div>
      {loading && (
        <div className="flex gap-2">
          <div className="flex items-center justify-center space-x-1 animate-pulse">
            <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
            <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
            <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
          </div>
          <div className="text-lg text-border">
            {texts.validating_guess_text}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageFeedback;
