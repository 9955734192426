import React, { useContext } from "react";
import { LanguageContext } from "../contexts/LanguageContext";

const UsernameSelection = ({
  username,
  setUsername,
  error,
  className,
  confirmButtonRender,
}) => {
  const { texts } = useContext(LanguageContext);

  const Input = () => {
    // Has to be the same button otherwise you lose focus from transitioning to states
    let className;
    if (!username) {
      className =
        "bg-gray-50 border border-gray-200 text-gray-900 placeholder-gray-400 text-lg rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-4 dark:bg-gray-100 dark:border-gray-400";
    }
    if (!className && error) {
      className =
        "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-lg rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-4 dark:bg-red-100 dark:border-red-400";
    }
    if (!className && !error) {
      className =
        "bg-green-50 border border-green-500 text-green-900 placeholder-green-700 text-lg rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-4 dark:bg-green-100 dark:border-green-400";
    }
    return (
      <div className="flex gap-2">
        <input
          key={"username-input"}
          className={className}
          value={username}
          autoFocus="autoFocus"
          onChange={(ev) => {
            setUsername(ev.target.value);
          }}
        />
        <div className="self-center">
          {confirmButtonRender && confirmButtonRender()}
        </div>
      </div>
    );
  };

  if (!username) {
    return (
      <div className={className}>
        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-500">
          Your username
        </label>

        <Input />
      </div>
    );
  }
  return (
    <div className={className}>
      {!error ? (
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-green-700 dark:text-green-500">
            {texts.ranking_input_field_label}
          </label>
          <Input />

          <p className="mt-2 text-sm text-green-600 dark:text-green-500">
            {texts.ranking_input_field_validate_succes}
          </p>
        </div>
      ) : (
        <div>
          <label className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500">
            {texts.ranking_input_field_label}
          </label>
          <Input />

          <p className="mt-2 text-sm text-red-600 dark:text-red-500">
            <span className="font-medium">Oops!</span>{" "}
            {texts.ranking_input_field_validate_error}
          </p>
        </div>
      )}
    </div>
  );
};

export default UsernameSelection;
